import React, { useRef, useState } from 'react';
import { Toggle } from '@panda/ui';
import classes from './NqNupsi.scss';
import { useNqNupsi } from '../../../redux/slices/nqNupsi';
import DropOut from '../../DropOut';
import { useTranslate, useTranslationActions } from '../../../redux/slices/translations';
import useKeyboardEvents from '../../hooks/useKeyboardEvents';
import useOutsideClick from '../../hooks/useOutsideClick';
import { isProductionEnvironment } from '../../../utils/environment';
import { useFeatureFlag } from '../../../redux/modules/userinfo';

const NqNupsi = () => {
	const { fetched: isNqNupsiFetched, data: NqNupsiState } = useNqNupsi();
	const hasFeatureFlag = useFeatureFlag('NQ_NUPSI');
	const { setDebug } = useTranslationActions();
	const translate = useTranslate();
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	if (!hasFeatureFlag || !isNqNupsiFetched || !NqNupsiState || !NqNupsiState.isNq) {
		return <></>;
	}

	return (
		<div id="nqnupsi" className={classes.nqnupsi} ref={ref}>
			<button
				type="button"
				onClick={() => setOpen(!open)}
				aria-controls="nqnupsi-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="nqnupsi-button"
			>
				<div className={classes.nqNupsiIcon} aria-label={translate('NQNUPSI_BUTTON_LABEL')} />
			</button>
			<DropOut id="nqnupsi-menu" open={open} ariaLabelledBy="nqnupsi-button">
				<ul>
					<li className={classes.withToggle}>
						<Toggle
							ariaLabel={translate('NQNUPSI_SHOW_LKS')}
							size="large"
							onChange={() => setDebug(!NqNupsiState.showLks)}
							checked={NqNupsiState.showLks}
						>
							{translate('NQNUPSI_SHOW_LKS')}
						</Toggle>
					</li>
					<li>
						<a
							href={
								isProductionEnvironment()
									? 'https://admin.live.sipgate.net'
									: 'https://admin.dev.sipgate.net'
							}
							target="_blank"
							rel="noreferrer"
						>
							{translate('NQNUPSI_HELPDESK_LINK')}
						</a>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};

export default NqNupsi;
