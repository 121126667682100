import { useSelector } from '../../utils/wrapper';
import { fetchAccountStatement } from './actions';
import { useAction } from '../..';

export const useAccountStatement = () => {
	const accountingState = useSelector(state => state.accounting);

	return {
		accountStatement: accountingState.fetchedAccountStatement ? accountingState : null,
		fetchAccountStatement: useAction(fetchAccountStatement),
	};
};
