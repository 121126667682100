import { useEffect, useMemo } from 'react';
import { SliceData, useAction } from '../..';
import { useSelector, useDispatch } from '../../utils/wrapper';
import { Tac } from '../productsTacs';
import { fetchProductTacs } from '../productsTacs/actions';
import { useUserInfo } from '../userinfo';
import {
	fetchIsProductBookingAllowed,
	fetchProductOptions,
	fetchProducts,
	forceFetchProductOptions,
	forceFetchProducts,
} from './actions';
import { Product, ProductOption, ProductType } from './types';

export const useProducts = (type: ProductType): SliceData<Product[]> => {
	const state = useSelector(s => s.products);
	const userinfo = useUserInfo();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchProducts(type, userinfo.domain));
	}, [dispatch, type, userinfo.domain]);

	return useMemo(() => {
		if (state.fetchedForProductType.includes(type)) {
			return {
				fetched: true,
				data: state.items.filter(product => product.type === type),
			};
		}

		return {
			fetched: false,
			data: null,
		};
	}, [type, state.fetchedForProductType, state.items]);
};

export type ProductDetails<P> = { product: P; tacs: Tac[]; isBookingAllowed: boolean };

// We explicitly break out of our ADR, because after trying for quite some time, we did not manage
// to find a way to work around the very fine-grained api structure.
//
// The fact we need to make separate API calls per-product, while at the same time handling multiple
// products in a single dialog breaks our usual approach of checking for existence before rendering
// the hook-using component.
export function useProduct<P extends Product | ProductOption>(
	product: P
): SliceData<ProductDetails<P>>;
export function useProduct<P extends Product | ProductOption>(
	product: P | null
): SliceData<ProductDetails<P>> | null;
export function useProduct<P extends Product | ProductOption>(
	product: P | null
): SliceData<ProductDetails<P>> | null {
	const state = useSelector(s => s.products);
	const tacState = useSelector(s => s.productsTacs);

	const dispatch = useDispatch();

	useEffect(() => {
		if (product) {
			dispatch(fetchIsProductBookingAllowed(product.id));
			dispatch(fetchProductTacs(product.id));
		}
	}, [dispatch, product]);

	return useMemo(() => {
		if (!product) {
			return null;
		}

		if (
			!state.fetchedIsProductBookingAllowed.includes(product.id) ||
			!tacState.fetchedForProducts.includes(product.id)
		) {
			return {
				fetched: false,
				data: null,
			};
		}

		return {
			fetched: true,
			data: {
				tacs: tacState.items[product.id],
				product,
				isBookingAllowed:
					state.itemsBookingIsAllowed.find(p => p.productId === product.id)?.allowed || false,
			},
		};
	}, [
		product,
		state.fetchedIsProductBookingAllowed,
		state.itemsBookingIsAllowed,
		tacState.fetchedForProducts,
		tacState.items,
	]);
}

export const useProductOptions = (productId: string): SliceData<ProductOption[]> => {
	const state = useSelector(s => s.products);
	const dispatch = useDispatch();
	const userinfo = useUserInfo();

	useEffect(() => {
		dispatch(fetchProductOptions(productId, userinfo.domain));
	}, [dispatch, productId, userinfo.domain]);

	return useMemo(() => {
		if (state.fetchedOptionsForProduct.includes(productId)) {
			return {
				fetched: true,
				data: state.options.filter(product => product.dependantProducts.includes(productId)),
			};
		}

		return {
			fetched: false,
			data: null,
		};
	}, [productId, state.fetchedOptionsForProduct, state.options]);
};

export const useProductActions = () => ({
	forceFetchProducts: useAction(forceFetchProducts),
	forceFetchProductOptions: useAction(forceFetchProductOptions),
});
