export type SelectionParsers<S> = { [key in keyof S]: SelectionParser<S[key]> };

export interface SelectionParser<Type> {
	validate: (value: string) => boolean;
	isDefault: (value: Type) => boolean;
	serialize: (value: Type) => string[];
	deserialize: (value: string[]) => Type;
	filterName?: string;
}

export const activeFilterCount = <S>(
	parsers: SelectionParsers<S>,
	selections: S,
	exclusions?: string[]
) => {
	const activeSelections: { [key: string]: boolean } = {};

	for (const name in selections) {
		const parser = parsers[name];

		if (parser.filterName && !parser.isDefault(selections[name])) {
			if (!exclusions || !exclusions.includes(parser.filterName)) {
				activeSelections[parser.filterName] = true;
			}
		}
	}

	return Object.values(activeSelections).length;
};

export const deserializeHash = <S>(parsers: SelectionParsers<S>, hash: string): S => {
	const result = {} as S;
	const parsedHash = new URLSearchParams(hash.substr(1));

	for (const name in parsers) {
		const values = parsedHash.getAll(name);
		const validatedValues = values.filter(parsers[name].validate);

		result[name] = parsers[name].deserialize(validatedValues);
	}

	return result;
};

export const serializeHash = <S>(parsers: SelectionParsers<S>, selections: S): string => {
	const params = new URLSearchParams();

	for (const name in selections) {
		const values = parsers[name].serialize(selections[name]);

		for (const value of values) {
			if (value !== '') {
				params.append(name, value);
			}
		}
	}

	const queryString = params.toString();

	if (queryString.length === 0) {
		return '';
	}
	return `#${params.toString()}`;
};

export const modifyHash = <S>(
	parsers: SelectionParsers<S>,
	changedValues: Partial<S>,
	hash: string
): string => {
	const selections = deserializeHash(parsers, hash);

	for (const name in changedValues) {
		if (changedValues[name] !== undefined) {
			selections[name] = changedValues[name]!;
		}
	}

	return serializeHash(parsers, selections);
};
