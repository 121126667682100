import {
	Button,
	HeadingBoundary,
	Headline,
	ListHeader,
	Option,
	Search,
	SearchNoResult,
} from '@panda/ui';
import { ManagedValidatorResult } from '@web-apps/forms';
import { localizeNumber } from '@web-apps/phonenumbers-utils';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { BatchUser } from '../../api/types/batch-user';
import { ApiDomain } from '../../api/types/userinfo';
import { formatAddress } from '../../components/address/Address';
import CenteredLogoSpinner from '../../components/spinner/CenteredLogoSpinner';
import { useAddresses } from '../../redux/modules/addresses';
import { useNumbers } from '../../redux/modules/numbers';
import { useTranslate } from '../../redux/slices/translations';
import { useUserInfo } from '../../redux/modules/userinfo';
import { useBatchUserImportUsers } from '../../redux/slices/batchUserImport';
import { usersPath } from '../../routes/paths';
import { useDialogs } from '../../routes/paths/dialogs';
import { mixpanel } from '../../third-party/mixpanel';
import { useInit } from '../../utils/initialize';
import BatchUserControlTableRow from './BatchUserControlTableRow';
import classes from './BatchUsersControlView.scss';
import { getValidatorsByField, ValidatedBatchUser } from './BatchUsersControlView.validators';

export default function BatchUsersControlView() {
	const params = useParams<{ jobId: string }>();
	const translate = useTranslate();
	const [searchTerm, setSearchTerm] = React.useState('');
	const userInfo = useUserInfo();
	const addresses = useAddresses();
	const [sortColumn, setSortColumn] = React.useState<keyof BatchUser>('firstname');
	const [enforceRerendering, setEnforceRerendering] = React.useState('');
	const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('ascending');
	const { fetched: numbersFetched, data: numbers } = useNumbers();
	const { getBatchUsers, updateBatchUser, batchUsers, usersFetched, deleteBatchUserById } =
		useBatchUserImportUsers();
	const dialogs = useDialogs();
	const [validatedUsers, setValidatedUsers] = React.useState<ValidatedBatchUser[]>([]);

	useInit(async () => {
		mixpanel.track('BATCH USER IMPORT CONTROL STARTED', {
			userCount: validatedUsers.length,
		});
		await getBatchUsers(params.jobId);
	});

	const usableNumbers = useMemo(() => {
		if (!numbersFetched) {
			return [];
		}

		return numbers
			.filter(number => number.type !== 'QUICKDIAL')
			.filter(number => validatedUsers.every(user => `${user.phoneNumberId}` !== number.id));
	}, [numbersFetched, numbers, validatedUsers]);

	const submitBatchJob = () => {
		const invalidUsers = validatedUsers
			.filter(user => Object.values(user.validation).some(validation => !validation.valid))
			.sort((a, b) => (a.id > b.id ? 1 : -1));

		if (invalidUsers.length > 0) {
			const el = document.getElementById(`tableRow${invalidUsers[0].id}`);
			el?.scrollIntoView({ behavior: 'smooth' });
			return;
		}

		mixpanel.track('BATCH USER IMPORT CONTROL FINISHED', {
			userCount: validatedUsers.length,
		});

		dialogs.batchImportExecuteJob.open();
	};

	const deleteUser = async (userId: string) => {
		if (validatedUsers.length === 1) {
			dialogs.batchImportDeleteLastRemainingUser.open();
		} else {
			await deleteBatchUserById(params.jobId, userId);
			setValidatedUsers(validatedUsers.filter(user => user.id !== userId));
		}
	};

	const filterBySearchTerm = (user: BatchUser) => {
		if (searchTerm === '') {
			return true;
		}

		const searchTermLower = searchTerm.toLowerCase();
		for (const [key, value] of Object.entries(user)) {
			if (typeof value === 'string' && value.toLowerCase().includes(searchTermLower)) {
				return true;
			}
			if (
				typeof value === 'boolean' &&
				value &&
				key === 'admin' &&
				searchTermLower.includes('admin')
			) {
				return true;
			}

			if (key === 'locationId' && addresses !== null) {
				const address = addresses.find(addressSearch => `${addressSearch.id}` === value);
				if (
					address !== undefined &&
					formatAddress(address).toLowerCase().includes(searchTermLower)
				) {
					return true;
				}
			}
		}
	};

	const validateSpecificField = useCallback(
		<T extends keyof BatchUser>(values: ValidatedBatchUser[], field: T, value: BatchUser[T]) => {
			const validator = getValidatorsByField(translate)[field];
			if (
				!validator ||
				typeof value === 'boolean' ||
				(field === 'quickdial' && (value === '' || value === null))
			) {
				return { valid: true, value: `${value}` } as ManagedValidatorResult<string>;
			}
			return validator(`${value}`, values);
		},
		[translate]
	);

	const revalidateUser = useCallback(
		(user: ValidatedBatchUser, otherUsers: ValidatedBatchUser[]): ValidatedBatchUser => {
			return {
				...user,
				validation: {
					firstname: validateSpecificField(otherUsers, 'firstname', user.firstname ?? ''),
					lastname: validateSpecificField(otherUsers, 'lastname', user.lastname ?? ''),
					email: validateSpecificField(otherUsers, 'email', user.email ?? ''),
					locationId: validateSpecificField(otherUsers, 'locationId', `${user.locationId}`),
					phoneNumberId: validateSpecificField(otherUsers, 'phoneNumberId', user.phoneNumberId),
					quickdial: validateSpecificField(otherUsers, 'quickdial', user.quickdial ?? ''),
				},
			};
		},
		[validateSpecificField]
	);

	const validateAllUsers = useCallback(
		(usersToValidate: BatchUser[]): ValidatedBatchUser[] => {
			const validated: ValidatedBatchUser[] = [];
			const emptyValidatedUsers: ValidatedBatchUser[] = usersToValidate.map(user => ({
				...user,
				validation: {},
			}));
			emptyValidatedUsers.forEach(user => {
				validated.push(revalidateUser(user, emptyValidatedUsers));
			});
			return validated;
		},
		[revalidateUser]
	);

	useEffect(() => {
		setValidatedUsers(validateAllUsers(batchUsers));
	}, [batchUsers, setValidatedUsers, validateAllUsers]);

	const debouncedUpdates = React.useRef(
		new Map<string, (user: ValidatedBatchUser) => void>()
	).current;

	const changeUserInApi = (updatedUser: ValidatedBatchUser) => {
		if (!debouncedUpdates.has(updatedUser.id)) {
			debouncedUpdates.set(
				updatedUser.id,
				debounce((user: ValidatedBatchUser) => {
					updateBatchUser(params.jobId, user);
				}, 500)
			);
		}

		debouncedUpdates.get(updatedUser.id)!(updatedUser);
	};

	const changeField = <T extends keyof BatchUser>(
		userId: string,
		field: T,
		value: BatchUser[T]
	) => {
		const optimisticUsers = validatedUsers.map(user => {
			if (user.id === userId) {
				return revalidateUser(
					{
						...user,
						[field]: value === '' ? null : value,
					},
					validatedUsers
				);
			}
			return user;
		});
		const allValidatedUsers = validateAllUsers(optimisticUsers);

		setValidatedUsers(allValidatedUsers);
		const changedUser = allValidatedUsers.find(user => user.id === userId);
		if (!changedUser) {
			return;
		}
		setEnforceRerendering(`${Date.now()}`);
		mixpanel.track('BATCH USER IMPORT CONTROL CHANGE USER', {
			field,
		});
		changeUserInApi(allValidatedUsers.find(user => user.id === userId)!);
	};

	if (!params.jobId) {
		return <Redirect to={usersPath.build()} />;
	}

	if (!usersFetched || addresses === null || numbers === null) {
		return <CenteredLogoSpinner />;
	}

	const getUserNumberOptions = (user: BatchUser, domain: ApiDomain): JSX.Element[] => {
		const userNumberOptions = usableNumbers.map(number => (
			<Option key={number.id} value={number.id}>
				{localizeNumber(number.number, domain)}
			</Option>
		));

		if (user.phoneNumberId) {
			userNumberOptions.unshift(
				<Option key="userNumber" value={`${user.phoneNumberId}`}>
					{localizeNumber(
						numbers.find(number => number.id === `${user.phoneNumberId}`)!.number,
						domain
					)}
				</Option>
			);
			userNumberOptions.unshift(
				<Option key="unselectNumber" value="">
					{translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_NUMBER_UNSELECT')}
				</Option>
			);
		}
		if (userNumberOptions.length === 0) {
			return [
				<Option disabled key="noNumber" value="">
					{translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_NUMBER_NONE_AVAILABLE')}
				</Option>,
			];
		}
		return userNumberOptions;
	};

	const filteredUsers = validatedUsers.filter(filterBySearchTerm);

	const renderTable = (users: ValidatedBatchUser[]) => {
		return (
			<table>
				<ListHeader
					columns={[
						{
							hidden: false,
							id: 'firstname',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_FIRSTNAME_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'lastname',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_LASTNAME_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'email',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_EMAIL_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'admin',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_ROLE_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'locationId',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_LOCATION_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'phoneNumberId',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_NUMBER_HEADLINE'),
							sortable: true,
						},
						{
							hidden: false,
							id: 'quickdial',
							label: translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_QUICKDIAL_HEADLINE'),
							sortable: true,
						},
					]}
					sortBy={(column, direction) => {
						setSortColumn(column);
						setSortDirection(direction);
						setEnforceRerendering(`${Date.now()}`);
					}}
					sortedBy={{
						column: sortColumn,
						direction: sortDirection,
					}}
				/>

				<tbody>
					{users
						.sort((a, b) => {
							const multiplier = sortDirection === 'ascending' ? 1 : -1;

							if (
								sortColumn === 'firstname' ||
								sortColumn === 'lastname' ||
								sortColumn === 'email'
							) {
								const firstValue = a[sortColumn] ?? '';
								const secondValue = b[sortColumn] ?? '';
								return firstValue.localeCompare(secondValue) * multiplier;
							}

							if (sortColumn === 'admin') {
								const firstValue = a[sortColumn] ? 1 : 0;
								const secondValue = b[sortColumn] ? 1 : 0;
								return (firstValue - secondValue) * multiplier;
							}

							if (sortColumn === 'locationId') {
								const firstValue = addresses.find(address => `${address.id}` === `${a.locationId}`);
								const secondValue = addresses.find(
									address => `${address.id}` === `${b.locationId}`
								);

								const firstFormattedValue = firstValue ? formatAddress(firstValue) : '';
								const secondFormattedValue = secondValue ? formatAddress(secondValue) : '';

								return firstFormattedValue.localeCompare(secondFormattedValue) * multiplier;
							}

							if (sortColumn === 'phoneNumberId') {
								const firstValue = a.phoneNumberId ?? '';
								const secondValue = b.phoneNumberId ?? '';
								const maybeFirstNumber = numbers.find(number => number.id === `${firstValue}`);

								const maybeSecondNumber = numbers.find(number => number.id === `${secondValue}`);

								const localizedValue = maybeFirstNumber
									? localizeNumber(maybeFirstNumber.number, userInfo.domain)
									: '';
								const localizedSecondValue = maybeSecondNumber
									? localizeNumber(maybeSecondNumber.number, userInfo.domain)
									: '';

								return localizedValue.localeCompare(localizedSecondValue) * multiplier;
							}

							if (sortColumn === 'quickdial') {
								const firstValue = a.quickdial ?? '';
								const secondValue = b.quickdial ?? '';
								return firstValue.localeCompare(secondValue) * multiplier;
							}

							return 0;
						})
						.map(user => {
							const hasErrors =
								Object.keys(user.validation).length > 0 &&
								Object.values(user.validation).some(validation => !validation.valid);

							return (
								<BatchUserControlTableRow
									key={user.id}
									user={user}
									enforce={enforceRerendering}
									changeField={changeField}
									deleteUser={deleteUser}
									hasErrors={hasErrors}
									getUserNumberOptions={getUserNumberOptions}
									isLastRemainingUser={validatedUsers.length === 1}
								/>
							);
						})}
				</tbody>
			</table>
		);
	};

	const getHint = (users: ValidatedBatchUser[]) => {
		const invalidUsers = users.filter(
			user =>
				user.validation && Object.values(user.validation).some(validation => !validation.valid)
		);
		if (invalidUsers.length === 1) {
			return (
				<div className={classes.errorMessage}>
					{translate('BATCH_USER_IMPORT_CONTROL_VIEW_FOOTER_INVALID_SINGULAR', invalidUsers.length)}
				</div>
			);
		}
		if (invalidUsers.length > 1) {
			return (
				<div className={classes.errorMessage}>
					{translate('BATCH_USER_IMPORT_CONTROL_VIEW_FOOTER_INVALID_PLURAL', invalidUsers.length)}
				</div>
			);
		}

		return (
			<div className={classes.hintMessage}>
				{translate('BATCH_USER_IMPORT_CONTROL_VIEW_FOOTER_VALID')}
			</div>
		);
	};

	return (
		<div className={classes.wrapper}>
			<main>
				<HeadingBoundary levelOverride={1}>
					<Headline>{translate('BATCH_USER_IMPORT_CONTROL_VIEW_HEADLINE')}</Headline>
					<p className={classes.subline}>
						{translate.markdown.inline(
							'BATCH_USER_IMPORT_CONTROL_VIEW_SUBLINE',
							validatedUsers.length.toString()
						)}
					</p>
					<div className={classes.tableContainer}>
						<div className={classes.search}>
							<Search
								value={searchTerm}
								onChange={setSearchTerm}
								placeholder={translate('BATCH_USER_IMPORT_CONTROL_VIEW_SEARCH_PLACEHOLDER')}
								resultCount={validatedUsers.length}
								landmark
							/>
						</div>
						{filteredUsers.length >= 1 && renderTable(filteredUsers)}
						{filteredUsers.length < 1 && searchTerm && (
							<div className={classes.noResults}>
								<SearchNoResult searchTerm={searchTerm} />
							</div>
						)}
					</div>
				</HeadingBoundary>
			</main>
			<footer>
				{getHint(validatedUsers)}
				<div className={classes.buttons}>
					<Button
						onClick={dialogs.batchImportCancelJob.open}
						type="button"
						variant="quiet"
						action="trigger"
						size="xlarge"
					>
						{translate('BATCH_USER_IMPORT_CONTROL_VIEW_FOOTER_CANCEL_BUTTON_LABEL')}
					</Button>
					<Button
						type="button"
						variant="loud"
						action="trigger"
						size="xlarge"
						onClick={submitBatchJob}
						disabled={validatedUsers.length === 0}
					>
						{translate('CONTINUE', validatedUsers.length.toString())}
					</Button>
				</div>
			</footer>
		</div>
	);
}
