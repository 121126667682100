import { PhoneNumber, PhoneNumberBlock, QuickdialNumber } from './types';

export const selectNumber = (numbers: PhoneNumber[], numberId: string) =>
	numbers.find(item => item.id === numberId);

export const selectAnyNumberExeptGiven = (numbers: PhoneNumber[], numberId: string) =>
	numbers.find(number => number.id !== numberId);

export const selectQuickdial = (numbers: PhoneNumber[], numberId: string) =>
	numbers
		.filter((number): number is QuickdialNumber => number.type === 'QUICKDIAL')
		.find(number => number.id === numberId);

export const selectNumberByType = (numbers: PhoneNumber[], types: PhoneNumber['type'][] = []) =>
	numbers.filter(number => types.includes(number.type));

export const selectNumbersByRoutedEndpoint = (numbers: PhoneNumber[], endpointId: string) => {
	return numbers.filter(number => number.endpointId === endpointId);
};

export const selectNumbersByNumber = (numbers: PhoneNumber[], number: string) => {
	return numbers.find(phoneNumber => phoneNumber.number === number);
};

export const selectNumbersByIds = (numbers: PhoneNumber[], ids: string[]) => {
	return numbers.filter(number => ids.includes(number.id));
};

export const selectNumbersByRoutedEndpoints = (numbers: PhoneNumber[], endpointIds: string[]) => {
	return numbers.filter(number => endpointIds.includes(number.endpointId));
};

export const selectNumbersForAddress = (numbers: PhoneNumber[], addressId: string) => {
	return numbers.filter(number => number.addressId === addressId);
};

export const sortPhoneNumbers = (numbers: PhoneNumber[]) => {
	const landline = numbers
		.filter(number => number.type === 'LANDLINE')
		.sort((a, b) => a.number.localeCompare(b.number));

	const mobile = numbers
		.filter(number => number.type === 'MOBILE')
		.sort((a, b) => a.number.localeCompare(b.number));

	const international = numbers
		.filter(number => number.type === 'INTERNATIONAL')
		.sort((a, b) => a.number.localeCompare(b.number));

	const quickdial = numbers
		.filter(number => number.type === 'QUICKDIAL')
		.sort((a, b) => a.number.localeCompare(b.number));

	return [...landline, ...mobile, ...international, ...quickdial];
};

export const selectNumbersByBlockId = (numbers: PhoneNumber[], blockId: string) =>
	numbers.filter(number => number.blockId === blockId);

export const selectSharedPrefix = (numbers: string[]) => {
	const sorted = [...numbers].sort();

	if (sorted.length === 0) {
		return '';
	}

	const first = sorted[0];
	const last = sorted[sorted.length - 1];

	for (let charIndex = 0; charIndex < first.length; charIndex += 1) {
		if (last.length <= charIndex) {
			return last;
		}

		if (first[charIndex] !== last[charIndex]) {
			return first.slice(0, charIndex);
		}
	}

	return first;
};

export const selectGroupedByBlock = (numbers: PhoneNumber[]) => {
	const result: (PhoneNumber | PhoneNumberBlock)[] = [];

	numbers.forEach(number => {
		if (!number.blockId) {
			result.push(number);
		} else if (!result.some(e => e.type === 'BLOCK' && e.id === number.blockId)) {
			const blockNumbers = selectNumbersByBlockId(numbers, number.blockId);
			const baseNumber = selectSharedPrefix(blockNumbers.map(n => n.number));

			result.push({
				type: 'BLOCK',

				id: number.blockId,
				numbers: blockNumbers,
				baseNumber,

				extensionSize:
					blockNumbers.reduce((len, num) => Math.max(len, num.number.length), 0) -
					baseNumber.length,
			});
		}
	});

	return result;
};
