import { createSlice, UnknownAction } from '@reduxjs/toolkit';
import { handleActions } from '../..';
import { setFaxlineAlias } from '../faxlines';
import { setPhonelineAlias } from '../phonelines';
import { NumberState, PhoneNumber } from './types';
import { ApiPhoneNumber } from '../../../api/types/numbers';
import { sortPhoneNumbers } from './selectors';
import * as actions from './actions';
import { changeTrunkRouting } from '../trunks';
import { setGroupAlias } from '../groups';
import { createAcd, setAcdNumbers, deleteAcd } from '../../slices/acds';
import { moveAddress } from '../addresses';

const initialState: NumberState = {
	items: [],
	fetched: false,
	fetching: false,
};

export const extendNonQuickDialNumbers = (number: ApiPhoneNumber): PhoneNumber => {
	if (number.type === 'QUICKDIAL') {
		return number;
	}

	return number;
};

const rtkReducer = createSlice({
	name: 'numbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(createAcd.fulfilled, (state, { payload }) => ({
				...state,
				items: state.items.map(number => {
					if (payload.phoneNumberIds.includes(Number.parseInt(number.id, 10))) {
						return {
							...number,
							endpointId: payload.id,
							endpointAlias: payload.name,
						};
					}
					return number;
				}),
			}))
			.addCase(setAcdNumbers.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(number => {
					if (action.meta.arg.phoneNumberIds.includes(number.id)) {
						return {
							...number,
							endpointId: action.meta.arg.id,
							endpointAlias: action.meta.arg.name,
						};
					}

					if (number.endpointId === action.meta.arg.id) {
						return {
							...number,
							endpointId: '',
							endpointAlias: '',
						};
					}

					return number;
				}),
			}))
			.addCase(deleteAcd.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(number => {
					if (number.endpointId === action.meta.arg.acdId) {
						return {
							...number,
							endpointId: '',
							endpointAlias: '',
						};
					}

					return number;
				}),
			}));
	},
}).reducer;

const legacyReducer = handleActions<
	NumberState,
	PossibleActions<
		| typeof actions
		| typeof setPhonelineAlias
		| typeof setFaxlineAlias
		| typeof changeTrunkRouting
		| typeof setGroupAlias
		| typeof moveAddress
	>
>(
	{
		NUMBERS_FETCH_ACCESSIBLE_PENDING: state => ({ ...state, fetching: true }),
		NUMBERS_FETCH_ACCESSIBLE_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: sortPhoneNumbers(payload[0].items.map(extendNonQuickDialNumbers)).map(number => {
				const acd = payload[1].items.find(acdItem =>
					acdItem.phoneNumberIds.includes(Number.parseInt(number.id, 10))
				);

				return {
					...number,
					endpointId: acd ? acd.id : number.endpointId,
					endpointAlias: acd ? acd.name : number.endpointAlias,
				};
			}),
		}),

		NEW_ADDRESS_MOVE_SUCCESS: (state, { payload }) =>
			payload.numbers
				? {
						...state,
						fetched: true,
						items: sortPhoneNumbers(payload.numbers.map(extendNonQuickDialNumbers)),
					}
				: state,
		NUMBER_SET_SETTINGS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => {
				if (number.id === data.numberId) {
					return {
						...number,
						endpointId: data.endpointId || '',
						endpointAlias: data.endpointAlias || '',
					};
				}
				return { ...number };
			}),
		}),
		FAXLINE_SET_ALIAS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => ({
				...number,
				endpointAlias: number.endpointId === data.faxlineId ? data.alias : number.endpointAlias,
			})),
		}),
		PHONELINE_SET_ALIAS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => ({
				...number,
				endpointAlias: number.endpointId === data.phonelineId ? data.alias : number.endpointAlias,
			})),
		}),
		QUICKDIAL_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => ({
				...number,
				number: number.id === data.numberId ? data.number : number.number,
			})),
		}),
		QUICKDIAL_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
		}),
		QUICKDIAL_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: [...state.items.filter(item => item.id !== data.numberId)],
		}),
		TRUNKS_CHANGE_ROUTING_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => {
				if (data.change.removed.includes(number.id)) {
					return {
						...number,
						endpointId: '',
						endpointAlias: '',
					};
				}

				if (data.change.added.includes(number.id)) {
					return {
						...number,
						endpointId: data.trunk.id,
						endpointAlias: data.trunk.alias,
					};
				}

				return number;
			}),
		}),
		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				items: state.items.map(number => {
					if (number.endpointId === data.groupId) {
						return {
							...number,
							endpointAlias: data.alias,
						};
					}

					return number;
				}),
			};
		},
	},
	initialState
);

export const reducer = (state: NumberState, action: UnknownAction) => {
	return rtkReducer(legacyReducer(state, action), action);
};
