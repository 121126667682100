import * as actions from './actions';
import { AppSubscriptionTypesState } from './types';
import { handleActions } from '../..';
import { ApiAppSubscriptionType } from '../../../api/types/app';
import { Bookable } from '../products';
import { SipgateDomain } from '../userinfo';

const initialState: AppSubscriptionTypesState = {
	fetching: false,
	fetched: false,
	items: [],
};
const mapApiAppSubscriptionTypeToProductWithDescription = (
	apiAppSubscriptionType: ApiAppSubscriptionType,
	domain: SipgateDomain
): Bookable => ({
	id: apiAppSubscriptionType.name,
	name: apiAppSubscriptionType.name,
	cost: {
		amount: {
			amount: apiAppSubscriptionType.price,
			currency: domain === 'sipgate.de' ? 'EUR' : 'GBP',
			fraction: 10_000,
		},
		interval: 'monthly',
		isNetto: true,
	},
});

export const appSubscriptionReducer = handleActions<
	AppSubscriptionTypesState,
	PossibleActions<typeof actions>
>(
	{
		APP_SUBSCRIPTION_TYPES_FETCH_PENDING: state => ({
			...state,
			fetching: true,
			fetched: false,
			items: [],
		}),
		APP_SUBSCRIPTION_TYPES_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload.items.map(item =>
				mapApiAppSubscriptionTypeToProductWithDescription(item, data.domain)
			),
		}),
	},
	initialState
);
