import { createSlice } from '@reduxjs/toolkit';

import { fetchAccountStatement } from './actions';
import { AccountingState, ApiAggregatedStatement, ApiStatement } from './types';

export const initialState: AccountingState = {
	statements: [],
	aggregatedStatements: [],
	balances: [],
	fetchingAccountStatement: false,
	fetchedAccountStatement: false,
};

export const slice = createSlice({
	name: 'accounting',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAccountStatement.pending, state => ({
			...state,
			fetchingAccountStatement: true,
		}));
		builder.addCase(fetchAccountStatement.fulfilled, (state, action) => {
			const existingStatements = state.statements.filter(
				stmt =>
					stmt.periodStart !== action.meta.arg.periodStart.toUTC().toMillis() &&
					stmt.periodEnd !== action.meta.arg.periodEnd.toUTC().toMillis()
			);
			const existingAggregatedStatements = state.aggregatedStatements.filter(stmt => {
				return (
					stmt.periodStart !== action.meta.arg.periodStart.toUTC().toMillis() &&
					stmt.periodEnd !== action.meta.arg.periodEnd.toUTC().toMillis()
				);
			});

			return {
				fetchingAccountStatement: false,
				fetchedAccountStatement: true,

				statements: [
					...existingStatements,
					...action.payload.statements.map((statements: ApiStatement) => ({
						...statements,
						periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
						periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
					})),
				],

				aggregatedStatements: [
					...existingAggregatedStatements,
					...action.payload.aggregatedStatements.map((statements: ApiAggregatedStatement) => ({
						...statements,
						periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
						periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
					})),
				],
				balances: [
					...state.balances.filter(
						blnc =>
							!(
								blnc.periodStart === action.meta.arg.periodStart.toUTC().toMillis() &&
								blnc.periodEnd === action.meta.arg.periodEnd.toUTC().toMillis()
							)
					),
					{
						periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
						periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
						balanceStart: action.payload.balanceStart,
						balanceEnd: action.payload.balanceEnd,
					},
				],
			};
		});
		builder.addCase(fetchAccountStatement.rejected, state => ({
			...state,
			fetchingAccountStatement: false,
			fetchedAccountStatement: false,
		}));
	},
});

export const reducer = slice.reducer;
