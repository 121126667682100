import { createSlice } from '@reduxjs/toolkit';

import { ApiCommission, ApiReferredCustomer } from '../../../api/types/affiliate';
import {
	consentAffiliateLogin,
	createCommissionPayoutAccount,
	deleteCommissionPayoutAccount,
	fetchAffiliateInfo,
	fetchAffiliateLoginConsentStatus,
	fetchCommissionBalance,
	fetchCommissionPayoutAccount,
	fetchCommissionReceipts,
	fetchReferallComissions,
	fetchReferredCustomers,
	payoutCommissionBalance,
	revokeAffiliateLoginConsent,
} from './actions';
import { AffiliateState } from './types';

export const initialState: AffiliateState = {
	fetching: false,
	fetched: false,
	fetchingCommissions: false,
	fetchedCommissions: false,
	fetchedBalance: false,
	fetchingBalance: false,
	fetchingConsent: false,
	fetchedConsent: false,
	balance: { credits: 0, currency: 'EUR' },
	referredCustomers: [],
	commissions: [],
	consent: false,
	fetchedCommissionReceipts: false,
	fetchingCommissionReceipts: false,
	commissionReceipts: [],
	fetchedAffiliateInfo: false,
	fetchingAffiliateInfo: false,
	affiliateInfo: {
		companyName: '',
		isCertified: false,
	},
	commissionPayoutAccount: { iban: '', accountHolder: '' },
	fetchingCommissionPayoutAccount: false,
	fetchedCommissionPayoutAccount: false,
};

export const slice = createSlice({
	name: 'affiliate',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchReferredCustomers.pending, state => ({ ...state, fetching: true }));
		builder.addCase(fetchReferredCustomers.fulfilled, (state, action) => ({
			...state,
			fetching: false,
			fetched: true,
			referredCustomers: action.payload.items.map((item: ApiReferredCustomer) => ({
				masterSipId: item.customerId,
				domain: item.domain,
				city: item.city,
				commission: item.commission,
				createdAt: item.createdAt,
				company: item.company,
			})),
		}));
		builder.addCase(fetchReferredCustomers.rejected, state => ({ ...state, fetching: false }));
		builder.addCase(fetchReferallComissions.pending, state => ({
			...state,
			fetchingCommissions: true,
		}));
		builder.addCase(fetchReferallComissions.fulfilled, (state, action) => ({
			...state,
			fetchingCommissions: false,
			fetchedCommissions: true,
			commissions: action.payload.items.map((item: ApiCommission) => ({
				...item,
				moneyAmount: undefined,
				credits: item.moneyAmount.credits,
				currency: item.moneyAmount.currency,
			})),
		}));
		builder.addCase(fetchReferallComissions.rejected, state => {
			return {
				...state,
				fetchingCommissions: false,
			};
		});
		builder.addCase(fetchCommissionBalance.pending, state => ({ ...state, fetchingBalance: true }));
		builder.addCase(fetchCommissionBalance.fulfilled, (state, action) => ({
			...state,
			fetchingBalance: false,
			fetchedBalance: true,
			balance: action.payload.balance,
		}));
		builder.addCase(fetchCommissionBalance.rejected, state => ({
			...state,
			fetchingBalance: false,
		}));
		builder.addCase(fetchCommissionReceipts.pending, state => ({
			...state,
			fetchingCommissionReceipts: true,
		}));
		builder.addCase(fetchCommissionReceipts.fulfilled, (state, action) => ({
			...state,
			fetchingCommissionReceipts: false,
			fetchedCommissionReceipts: true,
			commissionReceipts: action.payload.items,
		}));
		builder.addCase(fetchCommissionReceipts.rejected, state => ({
			...state,
			fetchingCommissionReceipts: false,
		}));
		builder.addCase(payoutCommissionBalance.pending, state => ({
			...state,
			fetchingBalance: true,
			balance: { ...state.balance, credits: 0 },
		}));
		builder.addCase(payoutCommissionBalance.rejected, state => ({
			...state,
			fetchingBalance: false,
		}));
		builder.addCase(payoutCommissionBalance.fulfilled, state => ({
			...state,
			fetchingBalance: false,
			fetchedBalance: true,
		}));
		builder.addCase(fetchAffiliateLoginConsentStatus.pending, state => ({
			...state,
			fetchingConsent: true,
		}));
		builder.addCase(fetchAffiliateLoginConsentStatus.rejected, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
			consent: false,
		}));
		builder.addCase(fetchAffiliateLoginConsentStatus.fulfilled, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
			consent: true,
		}));
		builder.addCase(revokeAffiliateLoginConsent.pending, state => ({
			...state,
			consent: false,
			fetchingConsent: true,
		}));
		builder.addCase(revokeAffiliateLoginConsent.rejected, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
		}));
		builder.addCase(revokeAffiliateLoginConsent.fulfilled, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
			consent: false,
		}));

		builder.addCase(consentAffiliateLogin.fulfilled, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
			consent: true,
		}));
		builder.addCase(consentAffiliateLogin.rejected, state => ({
			...state,
			fetchingConsent: false,
			fetchedConsent: true,
		}));
		builder.addCase(consentAffiliateLogin.pending, state => ({
			...state,
			fetchingConsent: true,
			consent: true,
		}));
		builder.addCase(fetchAffiliateInfo.pending, state => ({
			...state,
			fetchingAffiliateInfo: true,
		}));
		builder.addCase(fetchAffiliateInfo.rejected, state => ({
			...state,
			fetchingAffiliateInfo: false,
			fetchedAffiliateInfo: true,
		}));
		builder.addCase(fetchAffiliateInfo.fulfilled, (state, action) => ({
			...state,
			fetchingAffiliateInfo: false,
			fetchedAffiliateInfo: true,
			affiliateInfo: action.payload,
		}));
		builder.addCase(fetchCommissionPayoutAccount.rejected, state => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
		}));
		builder.addCase(fetchCommissionPayoutAccount.fulfilled, (state, action) => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
			commissionPayoutAccount: action.payload,
		}));
		builder.addCase(fetchCommissionPayoutAccount.pending, state => ({
			...state,
			fetchingCommissionPayoutAccount: true,
			fetchedCommissionPayoutAccount: false,
		}));

		builder.addCase(createCommissionPayoutAccount.rejected, state => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
		}));
		builder.addCase(createCommissionPayoutAccount.fulfilled, (state, action) => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
			commissionPayoutAccount: action.payload,
		}));
		builder.addCase(createCommissionPayoutAccount.pending, state => ({
			...state,
			fetchingCommissionPayoutAccount: true,
			fetchedCommissionPayoutAccount: false,
		}));
		builder.addCase(deleteCommissionPayoutAccount.rejected, state => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
		}));
		builder.addCase(deleteCommissionPayoutAccount.fulfilled, state => ({
			...state,
			fetchingCommissionPayoutAccount: false,
			fetchedCommissionPayoutAccount: true,
			commissionPayoutAccount: { iban: '', accountHolder: '' },
		}));
		builder.addCase(deleteCommissionPayoutAccount.pending, state => ({
			...state,
			fetchingCommissionPayoutAccount: true,
			fetchedCommissionPayoutAccount: false,
		}));
	},
});

export const reducer = slice.reducer;
