import { extractIBAN, isValidIBAN } from 'ibantools';

import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

const sanitizeIBAN = (iban: string) => iban.replace(/ /g, '');

export const validateIBAN =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	(iban: string) => {
		const sanitizedIban = sanitizeIBAN(iban);
		const valid = isValidIBAN(sanitizedIban);

		if (valid) {
			return {
				valid: true,
				value: sanitizedIban,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'ERRORHINT_INVALID_IBAN'),
		};
	};

export const validateCommissionPayoutAllowedIBAN =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	(iban: string) => {
		const sanitizedIban = sanitizeIBAN(iban);
		const extractedResult = extractIBAN(sanitizedIban);
		if (extractedResult.countryCode === undefined) {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_DISALLOWED_COMMISSION_IBAN'),
			};
		}
		console.log(extractedResult.countryCode);
		if (
			[
				'BE',
				'BG',
				'DK',
				'DE',
				'EE',
				'FI',
				'FR',
				'GR',
				'IT',
				'HR',
				'LV',
				'LT',
				'LU',
				'MT',
				'NL',
				'AT',
				'PL',
				'PT',
				'RO',
				'SE',
				'SK',
				'SI',
				'ES',
				'CZ',
				'HU',
				'CY',
				'IS',
				'LI',
				'NO',
			].includes(extractedResult.countryCode)
		) {
			return {
				valid: true,
				value: sanitizedIban,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'ERRORHINT_DISALLOWED_COMMISSION_IBAN'),
		};
	};

export const validateGermanIBAN =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	(iban: string) => {
		const sanitizedIban = sanitizeIBAN(iban);
		const extractedResult = extractIBAN(sanitizedIban);

		if (extractedResult.countryCode === 'DE') {
			return {
				valid: true,
				value: sanitizedIban,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'ERRORHINT_NON_GERMAN_IBAN'),
		};
	};
