import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { LogoSpinner } from '../components/spinner/LogoSpinner';
import { LanguageAttributeUpdater } from '../helpers/LanguageAttributeUpdater';
import { lazyLoad, lazyLoadWithoutSpinner } from '../helpers/lazy-loading';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import UserRedirect from '../redirects/UserRedirect';
import WbciOptInRedirect from '../redirects/WbciOptInRedirect';
import { connect, ReduxProps } from '../redux';
import { fetchContracts, isBaseContract } from '../redux/modules/contracts';
import { fetchEvents, selectEventByName, selectForceEvents } from '../redux/modules/events';
import * as instana from '../third-party/instana';
import * as mixpanel from '../third-party/mixpanel';
import auth from '../utils/authenticate/auth';
import AfterVideoIdentView from '../views/AfterVideoIdentView';
import { fetchUserInfo } from '../redux/modules/userinfo';
import { fetchTranslations } from '../redux/slices/translations';
import { forceFetchRestrictions } from '../redux/modules/restrictions';
import { fetchTacs } from '../redux/slices/tacs';
import { ReduxState } from '../redux/types';
import { fetchAppProperties } from '../redux/slices/appProperties';
import BatchUsersControlView from '../views/batch-users/BatchUsersControlView';
import ForceWebView from '../views/forceweb/ForceWebView';
import WelcomeView from '../views/forceweb/welcome/WelcomeView';
import LogoutView from '../views/LogoutView';
import CSVMappingView from '../views/csv-mapping/CSVMappingView';
import {
	csvMappingPath,
	forcePath,
	googleContactCallbackPath,
	saferPayCallbackPath,
	softphonePath,
	welcomePath,
	welcomePathUk,
} from './paths';
import { DialogProvider } from './paths/dialogs';
import { SaferPayCallback } from './SaferPayCallback';
import { SatelliteRedirect } from './SatelliteRedirect';
import { SessionProvider } from './SessionContext';
import { GoogleContactCallback } from './GoogleContactImportCallback';

const LoadableDeprecatedDialogWrapper = lazyLoadWithoutSpinner(
	() => import('../layouts/DeprecatedDialogWrapper')
);

const LoadableDialogWrapper = lazyLoadWithoutSpinner(() => import('../layouts/DialogWrapper'));

const LoadableWebphoneWrapper = lazyLoad(() => import('../webphone/phone/WebphoneWrapper'));

const mapStateToProps = (state: ReduxState) => ({
	tacs: state.tacs,
	userinfo: state.userinfo,
	translations: state.translations,
	appProperties: state.appProperties,
	events: state.events,
	contracts: state.contracts,
});

const mapDispatchToProps = {
	fetchTacs,
	fetchUserInfo,
	fetchContracts,
	fetchTranslations,
	fetchAppProperties,
	fetchEvents,
	forceFetchRestrictions,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & RouteComponentProps;

class AuthenticatedRoot extends React.Component<Props> {
	public componentDidMount() {
		auth.initializeFromLocalStorage();

		this.props.fetchEvents();
		this.props.fetchTacs();
		this.props.fetchUserInfo();
		this.props.fetchAppProperties();
		this.props.fetchContracts();
		this.props.forceFetchRestrictions();

		if (this.props.userinfo.fetched) {
			this.props.fetchTranslations({ locale: this.props.userinfo.data.locale });
		}

		this.initiateInstana();
		this.initiateMixpanel();
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.userinfo.fetched &&
			this.props.userinfo.data.locale !== prevProps.userinfo.data?.locale
		) {
			this.props.fetchTranslations({ locale: this.props.userinfo.data.locale });
		}

		if (!prevProps.tacs.fetched || !prevProps.userinfo.fetched || !prevProps.contracts.fetched) {
			this.initiateInstana();
			this.initiateMixpanel();
		} else if (this.props.location !== prevProps.location) {
			instana.reportPageView(this.props.location.pathname);
			mixpanel.trackPageView(this.props.history.action === 'REPLACE');
		}

		if (
			!forcePath.regex.test(this.props.location.pathname) &&
			this.props.events.fetched &&
			selectForceEvents(this.props.events).length > 0
		) {
			this.props.history.replace(forcePath.build());
			return;
		}

		if (
			!welcomePath.regex.test(this.props.location.pathname) &&
			!welcomePathUk.regex.test(this.props.location.pathname) &&
			this.props.events.fetched &&
			this.props.userinfo.fetched &&
			selectForceEvents(this.props.events).length === 0 &&
			selectEventByName(this.props.events, 'WELCOMECOMMAND')
		) {
			let path: `/${string}` = welcomePath.build();

			if (this.props.userinfo.data.locale === 'en_GB') {
				path = welcomePathUk.build();
			}
			this.props.history.replace(path);
			return;
		}

		if (this.props.location.pathname !== prevProps.location.pathname) {
			instana.reportEvent('Navigation ', { meta: { pathname: this.props.location.pathname } });
		}
	}

	private initiateMixpanel() {
		if (!this.props.tacs.fetched || !this.props.userinfo.fetched || !this.props.contracts.fetched) {
			return;
		}

		if (this.props.tacs.accepted && this.props.userinfo.data.product === 'team') {
			mixpanel.init(
				this.props.userinfo.data,
				this.props.contracts.items.find(isBaseContract)?.preview || false
			);
			mixpanel.trackPageView(this.props.history.action === 'REPLACE');
		}
	}

	private initiateInstana() {
		if (!this.props.tacs.fetched || !this.props.userinfo.fetched) {
			return;
		}

		if (this.props.tacs.accepted && this.props.userinfo.data.flags.includes('FRONTEND_DEBUGGING')) {
			instana.init(this.props.userinfo.data);
			instana.reportPageView(this.props.location.pathname);
		} else {
			instana.uninit();
		}
	}

	public render() {
		if (
			this.props.events.fetched &&
			this.props.translations.fetched &&
			this.props.userinfo.fetched &&
			selectForceEvents(this.props.events).length > 0
		) {
			return (
				<>
					<SessionProvider userinfo={this.props.userinfo.data}>
						<Switch>
							<Route path={forcePath.path} component={ForceWebView} />
						</Switch>
					</SessionProvider>
				</>
			);
		}

		if (
			this.props.events.fetched &&
			this.props.userinfo.fetched &&
			this.props.translations.fetched &&
			selectEventByName(this.props.events, 'WELCOMECOMMAND')
		) {
			let path: `/${string}` = welcomePath.path;

			if (this.props.userinfo.data.locale === 'en_GB') {
				path = welcomePathUk.path;
			}

			return (
				<>
					<SessionProvider userinfo={this.props.userinfo.data}>
						<Switch>
							<Route path={path} component={WelcomeView} />
						</Switch>
					</SessionProvider>
				</>
			);
		}

		if (
			!this.props.translations.fetched ||
			!this.props.userinfo.fetched ||
			!this.props.events.fetched ||
			!this.props.appProperties.fetched
		) {
			return <LogoSpinner />;
		}

		const user = this.props.userinfo.data.sub;
		return (
			<SessionProvider userinfo={this.props.userinfo.data}>
				<LanguageAttributeUpdater userinfo={this.props.userinfo.data} />

				<SatelliteRedirect />

				<DialogProvider>
					<Switch>
						<Route path="/wbciOptIn/*" component={WbciOptInRedirect} />
						<Route path="/logout" component={LogoutView} />
						<Route path="/postverification" component={AfterVideoIdentView} />
						<Route path={saferPayCallbackPath.path} component={SaferPayCallback} />
						<Route path={googleContactCallbackPath.path} component={GoogleContactCallback} />
						<Route path="/:webuserId(w\d+)/(.*)" component={UserRedirect} />
						<Route path="/batch-users/:jobId" component={BatchUsersControlView} />
						<Route path={csvMappingPath.path} component={CSVMappingView} />
						<Route
							key="phone"
							path={softphonePath.path}
							render={() => <LoadableWebphoneWrapper webuserId={user} />}
						/>

						{/* eslint-disable-next-line no-restricted-syntax */}
						<Redirect from="/gsuite" to={softphonePath.path} />

						<Route render={() => <AuthenticatedLayout webuserId={user} />} />
					</Switch>
				</DialogProvider>

				<LoadableDialogWrapper />
				<LoadableDeprecatedDialogWrapper />
			</SessionProvider>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoot);
