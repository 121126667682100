import { UserInfo } from '../redux/modules/userinfo';
import { environment, normalizePath, appWebVersion, opaqueUserId } from './utils';

interface TemporaryIneum {
	(...args: unknown[]): string | undefined;
	q: IArguments[];
	l: number;
}

function debug(...args: unknown[]) {
	// The check for error or warn is definitely hacky, but its debug output so I guess who cares?
	if (localStorage.getItem('logInstanaEvents') || args.some(arg => /error|warn/i.test(`${arg}`))) {
		// eslint-disable-next-line no-restricted-syntax
		console.log(...args);
	}
}

export function prepareGlobalInstanaFunction() {
	window.InstanaEumObject = 'ineum';

	const ineum: TemporaryIneum = function ineum() {
		ineum.q.push(arguments); // eslint-disable-line prefer-rest-params
		return undefined;
	};
	ineum.q = [];
	ineum.l = new Date().getTime();

	window.ineum = ineum;

	window.ineum('reportingUrl', 'https://eum-blue-saas.instana.io');
	window.ineum('key', '5JnxZwXjSfKE_B7ofHVPCA');
	window.ineum('meta', 'version', appWebVersion());

	window.ineum('allowedOrigins', [
		/.*.api.sipgate.com.*/i,
		/.*.api.dev.sipgate.com.*/i,
		/.*.api.local.sipgate.com.*/i,
	]);

	window.ineum('page', normalizePath(window.location.pathname));
}

export function uninit() {
	// Prevent leaking memory by continually pushing into ineum.q
	window.ineum = () => undefined;
}

export function init(userInfo: UserInfo) {
	if (environment() !== 'live') {
		return;
	}

	const script = document.createElement('script');
	script.async = true;
	script.src = 'https://eum.instana.io/eum.min.js';
	script.setAttribute('crossorigin', 'anonymous');

	const firstScript = document.getElementsByTagName('script')[0];
	if (firstScript.parentNode) {
		firstScript.parentNode.insertBefore(script, firstScript);
	}

	window.ineum('user', opaqueUserId(userInfo));
}

export function reportError(error: Error, componentStack?: string) {
	window.ineum('reportError', error, { componentStack });
}

export function reportPageView(path: string) {
	window.ineum('page', normalizePath(path));
}

export function reportEvent(
	name: string,
	opts?: {
		error?: Error;
		meta?: {
			[key: string]: string | number | boolean;
		};
	}
) {
	if (opts?.error) {
		debug(`[INSTANA-EVENT]: ${name}`, opts.error, opts?.meta);
	} else if (opts?.meta) {
		debug(`[INSTANA-EVENT]: ${name}`, opts.meta);
	} else {
		debug(`[INSTANA-EVENT]: ${name}`);
	}

	window.ineum('reportEvent', name, opts);
}

async function reportAsyncSpan<T>(name: string, action: Promise<T>): Promise<T> {
	const startTime = new Date().getTime();

	try {
		const result = await action;

		debug(`< [INSTANA-SPAN]: ${name}`);
		window.ineum('reportEvent', name, {
			duration: new Date().getTime() - startTime,
		});

		return result;
	} catch (error) {
		debug(`< [INSTANA-SPAN]: ${name}`, error);
		if (error instanceof Error) {
			window.ineum('reportEvent', name, {
				error,
				duration: new Date().getTime() - startTime,
			});
		}

		throw error;
	}
}

export function reportSpan<T>(name: string, action: () => Promise<T>): Promise<T>;
export function reportSpan<T>(name: string, action: () => T): T;
export function reportSpan<T>(name: string, action: () => T | Promise<T>): T | Promise<T> {
	const startTime = new Date().getTime();
	debug(`> [INSTANA-SPAN]: ${name}`);

	try {
		const result = action();

		if (result instanceof Promise) {
			return reportAsyncSpan(name, result);
		}

		debug(`< [INSTANA-SPAN]: ${name}`);
		window.ineum('reportEvent', name, {
			duration: new Date().getTime() - startTime,
		});

		return result;
	} catch (error) {
		debug(`< [INSTANA-SPAN]: ${name}`, error);
		if (error instanceof Error) {
			window.ineum('reportEvent', name, {
				error,
				duration: new Date().getTime() - startTime,
			});
		}

		throw error;
	}
}

export function reportLog(level: 'error' | 'warn', args: unknown[], meta?: Record<string, string>) {
	// eslint-disable-next-line no-restricted-syntax
	console[level](...args);

	window.ineum('reportEvent', `console::${level}`, {
		error: args.find((arg): arg is Error => arg instanceof Error),
		meta: {
			...meta,
			message: args.join(' '),
		},
	});
}

export const instanaLog = {
	error: (...args: unknown[]) => reportLog('error', args, { context: 'browser' }),
	warn: (...args: unknown[]) => reportLog('warn', args, { context: 'browser' }),

	// eslint-disable-next-line no-restricted-syntax
	log: (...args: unknown[]) => console.log(...args),
};
