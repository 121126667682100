import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Location } from 'history';
import {
	ExtractRouteParams,
	generatePath,
	matchPath,
	Route,
	useHistory,
	useLocation,
} from 'react-router';
import { useReturnFocusTarget } from '@web-apps/focus-trap';
import { PathParameterTakingFunction } from '../routes/paths/types';
import { ViewStackProvider } from '../foundation/view-stack';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';

const Context = React.createContext<`/${string}` | null>(null);

interface OpenableDialogRoute<Path extends `/${string}`> {
	/** Open this dialog on top of another page */
	at: (path: `/${string}`) => this;

	/**
	 * Open this dialog on top of the current page
	 *
	 * The returned promise resolves when the dialog is closed.
	 */
	open: PathParameterTakingFunction<Path, Promise<void>>;

	/** Set the element to focus when the dialog closes */
	withReturnFocus: (returnFocus: () => HTMLElement | null) => OpenableDialogRoute<Path>;
}

interface DialogRoute<Path extends `/${string}`> extends OpenableDialogRoute<Path> {
	/**
	 * Assemble a new path which you may redirect to, to open this dialog on top
	 * of the current page
	 */
	build: PathParameterTakingFunction<Path, string>;
}

type DialogRoutes<Paths extends Record<string, `/${string}`>> = {
	[key in keyof Paths]: DialogRoute<Paths[key]>;
};

export function defineDialogs<Paths extends Record<string, `/${string}`>>(
	root: `/${string}`,
	paths: Paths
) {
	const rootPath = `/(.*?)${root}` as const;

	interface Props {
		children: (routes: { [key in keyof Paths]: `/${string}${Paths[key]}` }) => React.ReactNode;
	}

	const DialogProvider = (props: { children: React.ReactNode }) => {
		return <Context.Provider value={root}>{props.children}</Context.Provider>;
	};

	class Dialogs extends React.Component<Props> {
		public render() {
			return (
				<Route path={rootPath}>
					{/* Mounted as a child, to ensure we don't unmount it ever. Otherwise out-transitions break */}
					{({ match, location }) => {
						const routes = {} as { [key in keyof Paths]: `/${string}${Paths[key]}` };
						for (const [name, path] of Object.entries(paths)) {
							routes[name as keyof Paths] = `${
								(match ? match.url : location.pathname + root) as `/${string}`
							}${path as Paths[typeof name]}`;
						}

						return (
							<ViewStackProvider
								value={{
									// We need to ensure the `/dialog` part of the route gets stripped when closing
									// a dialog.
									mountPoint: `/${match ? match.params[0] : location.pathname}`,
									viewStack: [],
									transitionTarget: true,
									titleId: '',
									setTitle: () => {},
								}}
							>
								{this.props.children(routes)}
							</ViewStackProvider>
						);
					}}
				</Route>
			);
		}
	}

	const useDialogs = (debug?: boolean) => {
		const history = useHistory();
		const location = useLocation();
		const setReturnFocusTarget = useReturnFocusTarget();

		// This entire dance is only useful because we want to keep our dialog opening functions
		// referentially stable across rerenders. But they still need a reference to the currently opened
		// page, so we stuff that into a ref because I dont have any better or more elegant ideas.
		const locationRef = useRef(location);
		useEffect(() => {
			locationRef.current = location;
		}, [location, debug]);

		return useMemo(() => {
			const buildPathPrefix = (base: `/${string}` | null) => {
				if (base) {
					return base + root;
				}

				// If we open a dialog without any active route (e.g. business verification),
				// location.pathname is a `/`, which results in two leading slashes, which
				// is then parsed as an absolute URL (bc // is parsed as "the current protocol" by
				// browsers).
				const pathname = locationRef.current.pathname === '/' ? '' : locationRef.current.pathname;

				const match = matchPath(locationRef.current.pathname, {
					path: `/(.*?)${root}`,
					sensitive: true,
				});

				return match ? match.url : pathname + root;
			};

			const buildRoute = (
				path: `/${string}`,
				beforeOpen: () => void,
				prefix: `/${string}` | null
			) => {
				const at = (base: `/${string}`) => buildRoute(path, beforeOpen, base);

				const build = (params?: ExtractRouteParams<Paths[keyof Paths]>) =>
					buildPathPrefix(prefix) + generatePath(path, params);

				const open = (params?: ExtractRouteParams<Paths[keyof Paths]>) => {
					return new Promise<void>(resolve => {
						beforeOpen();

						history.push({
							pathname: build(params),
							hash: locationRef.current.hash,
						});

						const deregister = history.listen(({ pathname }) => {
							if (!matchPath(pathname, rootPath)) {
								deregister();
								resolve();
							}
						});
					});
				};

				const withReturnFocus = (returnFocus: () => HTMLElement | null) =>
					buildRoute(
						path,
						() => {
							beforeOpen();
							setReturnFocusTarget(returnFocus);
						},
						prefix
					);

				return { build, open, at, withReturnFocus };
			};

			const routes = {} as DialogRoutes<Paths>;
			for (const [name, path] of Object.entries(paths)) {
				routes[name as keyof Paths] = buildRoute(path, () => {}, null);
			}

			return routes;
		}, [history, setReturnFocusTarget]);
	};

	const withDialogs =
		<WithDialogsProps extends { dialogs: DialogRoutes<Paths> }>(
			Component: React.ComponentType<WithDialogsProps>
		) =>
		(props: Omit<WithDialogsProps, 'dialogs'>) => {
			// Allowed for HOC
			//
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <Component {...(props as WithDialogsProps)} dialogs={useDialogs()} />;
		};

	// Location is passed manually because otherwise the AuthenticatedLayout transition fucks
	// with the redirects...
	const DialogRedirect = (props: { location: Location }) => (
		<Route location={props.location} path={`${root}/(.*)`}>
			{({ match }) => <PageNotFoundRedirect postFix={match?.url} />}
		</Route>
	);

	return {
		Dialogs,
		useDialogs,
		withDialogs,
		DialogRedirect,
		DialogProvider,
	};
}

export const useDialogRoot = () => {
	const ctx = useContext(Context);

	if (!ctx) {
		throw new Error('Used useDialogRoot outside of DialogProvider');
	}

	return ctx;
};
