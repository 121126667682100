import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { SipgateDomain } from '../userinfo';

const shouldFetchAppSubscriptionTypes = (state: ReduxState) => {
	return !state.appSubscriptions.fetched && !state.appSubscriptions.fetching;
};

export const fetchAppSubcriptionTypes = createAction(
	'APP_SUBSCRIPTION_TYPES_FETCH',
	(domain: SipgateDomain, force?: boolean) => ({
		promise: () => api.getAppSubscriptionTypes(),
		shouldFetch: (state: ReduxState) => force || shouldFetchAppSubscriptionTypes(state),
		data: {
			domain,
		},
	})
);
