import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ApiBase64Upload } from '../../../api/types/identityVerification';
import { ReduxState } from '../../types';

const shouldFetchAccount = (state: ReduxState) => !state.account.fetching && !state.account.fetched;

export const fetchAccount = createAsyncThunk(
	'account/fetch',
	async (_forceFetch?: boolean) => api.getAccount(),
	{
		condition: (forceFetch, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchAccount(getState());
		},
	}
);

export const uploadLogo = createAsyncThunk(
	'account/uploadLogo',
	(base64Content: string) => {
		const request = {
			name: 'logo.png',
			content: base64Content,
		} as ApiBase64Upload;
		return api.uploadLogo(request);
	},
	{
		condition: (_arg, { getState }) => !getState().account.uploadingLogo,
	}
);

export const deleteLogo = createAsyncThunk('account/deleteLogo', () => api.deleteLogo());
