import { createAction } from '../..';
import { ReduxState } from '../../types';
import api from '../../../api';
import { TimeBasedForwardingSet } from './types';
import {
	convertForwardingSetToApiTimeBasedForwardings,
	convertForwardingSetToApiTimeBasedSet,
	convertTimeBasedForwardingsSet,
	fixDelay,
} from './mapping';

export const fetchTimeBasedForwardings = createAction(
	'TIME_BASED_FORWARDINGS_FETCH',
	(extensionId: string) => ({
		promise: async () => {
			const timeBasedSets = await api.getTimeBasedSets(extensionId);
			if (!timeBasedSets || timeBasedSets.items.length < 1) {
				return [];
			}

			return Promise.all(
				timeBasedSets.items.map(async timeBasedSet => {
					const forwardings = await api
						.getTimeBasedForwardings(extensionId, timeBasedSet.id)
						.catch(() => {
							return null;
						});
					return convertTimeBasedForwardingsSet(timeBasedSet, forwardings);
				})
			);
		},
		shouldFetch: (state: ReduxState) => {
			return (
				!state.forwardings.fetching[extensionId] &&
				!state.forwardings.fetched[extensionId] &&
				!state.forwardings.updating[extensionId]
			);
		},
		data: { extensionId },
	})
);

export const updateTimeBasedSets = createAction(
	'TIME_BASED_SETS_UPDATE',
	(extensionId: string, timeBasedSets: TimeBasedForwardingSet[]) => {
		return {
			promise: () =>
				api.saveTimeBasedSets(
					extensionId,
					timeBasedSets.map(convertForwardingSetToApiTimeBasedSet)
				),
			shouldFetch: (state: ReduxState) => !state.forwardings.updating[extensionId],
			data: { extensionId, timeBasedSets },
		};
	}
);

export const updateTimeBasedForwardings = createAction(
	'TIME_BASED_FORWARDINGS_UPDATE',
	(extensionId: string, forwardingSet: TimeBasedForwardingSet) => {
		if (!forwardingSet.id) throw new Error('forwardingSet.id is undefined');

		const apiForwardings = convertForwardingSetToApiTimeBasedForwardings(fixDelay(forwardingSet));

		return {
			promise: () => api.saveTimeBasedForwardings(extensionId, forwardingSet.id!, apiForwardings),
			shouldFetch: (state: ReduxState) => !state.forwardings.updating[extensionId],
			data: { extensionId, forwardingSetId: forwardingSet.id },
		};
	}
);
