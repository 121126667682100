import { assertChildTypes } from '@web-apps/react-utils';
import classNames from 'classnames';
import React from 'react';

import { Button } from '../../components/button/Button';
import { ContextMenu } from '../../components/contextMenu/ContextMenu';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { HeadingBoundary } from '../../components/heading/HeadingBoundary';
import { Headline } from '../../components/heading/Headline';
import { DownloadLink } from '../../components/links/DownloadLink';
import { ExternalLink } from '../../components/links/ExternalLink';
import { InternalLink } from '../../components/links/InternalLink';
import { usePandaContext } from '../../contexts/pandaContext';

export type Props = {
	/** Überschrift der `Section`. */
	heading: string;
	/**
	 * Wenn in der Sektion etwas zählbares angezeigt wird, kannst du hier die Gesamtanzahl neben der Überschrift anzeigen.
	 */
	count?: number;
	/**
	 * Die `description` kann benutzt werden, um unter der `Section`
	 * eine kurze Beschreibung hinzuzufügen.
	 */
	description?: React.ReactNode;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Bearbeiten.
	 */
	actionElements?: React.ReactNode[];
	/**
	 * Der Inhalt der `Section` kann mit jeglichen Panda-Komponenten und HTML bestückt werden.
	 */
	children: React.ReactNode;
};

const styles = {
	header: classNames(
		'min-h-40',
		'mb-8',
		'flex',
		'items-center',
		'justify-right',
		'flex-wrap',
		'gap-8'
	),
	heading: classNames(
		'relative',
		'font-brand',
		'font-bold',
		'text-gray-800',
		'm-0',
		'grow',
		'text-xl/24',
		'md:text-2xl/32',
		'pl-4'
	),
	count: classNames('font-meta', 'font-light', 'text-gray-600', 'text-viewheading-meta'),
	srCount: classNames('sr-only'),
	actionElements: classNames('flex', 'flex-wrap', 'gap-8'),
	body: classNames('bg-transparent', 'rounded', 'font-brand', 'text-gray-800'),
	description: classNames(
		'mt-4',
		'px-4',
		'font-brand',
		'font-light',
		'text-xs/14',
		'text-gray-600'
	),
};

export const Section = ({ heading, count, description, actionElements, children }: Props) => {
	assertChildTypes(actionElements, [
		Button,
		ExternalLink,
		InternalLink,
		DownloadLink,
		Dropdown,
		ContextMenu,
	]);

	const { languageKeys } = usePandaContext();

	return (
		<section>
			<header className={styles.header}>
				<Headline className={styles.heading}>
					{heading}
					{count ? (
						<>
							<span
								title={`${count} ${languageKeys.PANDA_DETAIL_VIEW_SECTION_TOTAL}`}
								aria-hidden="true"
								className={styles.count}
							>
								&nbsp;[{count}]
							</span>

							<span className={styles.srCount}>
								{languageKeys.PANDA_DETAIL_VIEW_SECTION_COUNT}: {count}
							</span>
						</>
					) : null}
				</Headline>
				<div className={styles.actionElements}>{actionElements}</div>
			</header>
			<HeadingBoundary>
				<div className={styles.body}>{children}</div>
				{description ? <div className={styles.description}>{description}</div> : null}
			</HeadingBoundary>
		</section>
	);
};
