import { DateTime } from 'luxon';
import { Cost } from '../../../components/bookables/prices/util';

export type PhonenumberType =
	| 'GERMAN_LANDLINE'
	| 'GERMAN_LANDLINE_BLOCK'
	| 'GERMAN_LANDLINE_PROLONGATION_PARENT'
	| 'GERMAN_LANDLINE_PROLONGATION_CHILD'
	| 'GERMAN_MOBILE'
	| 'GERMAN_MOBILE_BLOCK'
	| 'UK_LANDLINE'
	| 'UK_LANDLINE_GROUP'
	| 'UK_NON_GEOGRAPHIC'
	| 'INTERNATIONAL'
	| 'GERMAN_LANDLINE_PREVIEW'
	| 'UNKNOWN'
	| 'UNKNOWN_BLOCK';

export type RoutingTargetType =
	| 'CONFERENCE_ROOM'
	| 'USER'
	| 'GROUP'
	| 'TRUNK'
	| 'REGISTER'
	| 'MOBILE'
	| 'FAX'
	| 'IVR'
	| 'ACD'
	| 'UNKNOWN';

interface PhonenumberRoutingBase {
	targetType: Exclude<RoutingTargetType, 'USER'>;
	targetId: string;
	displayAlias: string | null;
}

interface PhonenumberRoutingPhoneline {
	targetType: 'USER';
	targetOwner: string;
	targetId: string;
	displayAlias: string | null;
}

export type PhonenumberRouting = PhonenumberRoutingBase | PhonenumberRoutingPhoneline;

export interface PhonenumberCancellation {
	terminationDate: DateTime;
	revocable: boolean;
}

export interface PhonenumberContract {
	contractId: string;
	nextCancellationDate: DateTime | null;
	cancellation: null | PhonenumberCancellation;
	product: {
		productId: string;
		productName: string;
		cost: Cost;
	};
}

interface BasePhonenumber {
	type: PhonenumberType;
	e164Number: string;
	routing: null | PhonenumberRouting;
	contract: null | PhonenumberContract;
}

interface UnknownPorting {
	direction: 'UNKNOWN';
	dienstanbieterKennung: null;
	state: 'UNKNOWN';
	date: null;
	optInUntil: null;
}

interface OptInPorting {
	direction: 'EXPORT';
	dienstanbieterKennung: null;
	state: 'OPT_IN';
	date: null;
	optInUntil: DateTime;
}

interface BasePorting {
	direction: 'IMPORT' | 'EXPORT';
	dienstanbieterKennung: string;
	state: 'IN_PROGRESS' | 'ERROR' | 'COMPLETE';
	date: DateTime;
	optInUntil: null;
}

export type Porting = BasePorting | OptInPorting | UnknownPorting;

export interface GermanLandlineNumber extends BasePhonenumber {
	type: 'GERMAN_LANDLINE';
	addressId: string;
	porting: Porting | null;
}

export interface GermanLandlineNumberBlock extends BasePhonenumber {
	type: 'GERMAN_LANDLINE_BLOCK';
	numbers: (GermanLandlineNumber | GermanLandlineProlongationParent)[];
}

export interface GermanLandlineProlongationParent extends BasePhonenumber {
	type: 'GERMAN_LANDLINE_PROLONGATION_PARENT';
	numbers: GermanLandlineProlongationChild[];
	porting: Porting | null;
	baseContract: PhonenumberContract;
}

export interface GermanLandlineProlongationChild extends BasePhonenumber {
	type: 'GERMAN_LANDLINE_PROLONGATION_CHILD';
	addressId: string;
	routing: null | PhonenumberRouting;
}

export interface GermanMobileNumber extends BasePhonenumber {
	type: 'GERMAN_MOBILE';
	porting: Porting | null;
}

export interface GermanMobileBlock extends BasePhonenumber {
	type: 'GERMAN_MOBILE_BLOCK';
	numbers: GermanMobileNumber[];
}

export interface GermanPreviewNumber extends BasePhonenumber {
	type: 'GERMAN_LANDLINE_PREVIEW';
}

export interface InternationalNumber extends BasePhonenumber {
	type: 'INTERNATIONAL';
}

export interface UKLandlineNumber extends BasePhonenumber {
	type: 'UK_LANDLINE';
	addressId: string;
}

export interface UKNonGeographicNumber extends BasePhonenumber {
	type: 'UK_NON_GEOGRAPHIC';
	addressId: string;
}

export interface UKLandlineNumberGroup extends BasePhonenumber {
	type: 'UK_LANDLINE_GROUP';
	numbers: UKLandlineNumber[];
}

export interface UnknownNumber extends BasePhonenumber {
	type: 'UNKNOWN';
}

export interface UnknownNumberBlock extends BasePhonenumber {
	type: 'UNKNOWN_BLOCK';
	numbers: (
		| GermanLandlineNumber
		| GermanLandlineProlongationParent
		| GermanMobileNumber
		| InternationalNumber
		| UKLandlineNumber
		| UnknownNumber
	)[];
}

export type Phonenumber =
	| GermanLandlineNumber
	| GermanLandlineNumberBlock
	| GermanLandlineProlongationParent
	| GermanMobileNumber
	| GermanMobileBlock
	| GermanPreviewNumber
	| InternationalNumber
	| UKLandlineNumber
	| UKLandlineNumberGroup
	| UKNonGeographicNumber
	| UnknownNumber
	| UnknownNumberBlock;

export interface PhonenumbersState {
	fetching: boolean;
	fetched: boolean;
	items: Phonenumber[];
}

export const isPhonenumberWithPorting = (
	number: Phonenumber | GermanLandlineProlongationChild
): number is GermanLandlineNumber | GermanMobileNumber | GermanLandlineProlongationParent => {
	return (
		(number as GermanLandlineNumber | GermanMobileNumber | GermanLandlineProlongationParent)
			.porting !== undefined
	);
};

export const isPhonenumberWithChildren = (
	number: Phonenumber | GermanLandlineProlongationChild
): number is
	| GermanLandlineNumberBlock
	| GermanMobileBlock
	| UnknownNumberBlock
	| GermanLandlineProlongationParent
	| UKLandlineNumberGroup => {
	return 'numbers' in number;
};

export const isPhonenumberBlock = (
	number: Phonenumber | GermanLandlineProlongationChild
): number is
	| GermanLandlineNumberBlock
	| GermanMobileBlock
	| UnknownNumberBlock
	| UKLandlineNumberGroup => {
	return isPhonenumberWithChildren(number) && number.type !== 'GERMAN_LANDLINE_PROLONGATION_PARENT';
};

export const isPhonenumberInOngoingImportPorting = (
	number: Phonenumber | GermanLandlineProlongationChild
) => {
	return (
		'porting' in number &&
		number.porting !== null &&
		number.porting.direction === 'IMPORT' &&
		number.porting.state !== 'COMPLETE'
	);
};

export const hasRoutingWithTargetType = (
	number: Phonenumber | GermanLandlineProlongationChild,
	targetType: RoutingTargetType
): number is Phonenumber | GermanLandlineProlongationChild => {
	const withRouting = number as { routing: PhonenumberRouting };
	return withRouting.routing !== null && withRouting.routing.targetType === targetType;
};

export const hasRoutingPhoneline = (
	number: Phonenumber | GermanLandlineProlongationChild
): number is Phonenumber & { routing: PhonenumberRoutingPhoneline } => {
	return hasRoutingWithTargetType(number, 'USER');
};

export type BookablePhonenumberType =
	| 'GERMAN_LANDLINE'
	| 'GERMAN_LANDLINE_PROLONGATION'
	| 'GERMAN_MOBILE'
	| 'UK_LANDLINE'
	| 'INTERNATIONAL'
	| 'UNKNOWN';

export type PhonenumberProduct = {
	id: string;
	type: BookablePhonenumberType;
	productName: string;
	recurringCost: Cost;
	setupCost: Cost;
};
