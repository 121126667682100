import { Button, Checkbox, Input, Option, Select } from '@panda/ui';
import classnames from 'classnames';
import React from 'react';

import { BatchUser } from '../../api/types/batch-user';
import { ApiDomain } from '../../api/types/userinfo';
import { formatAddress } from '../../components/address/Address';
import { useAddresses } from '../../redux/modules/addresses';
import { useTranslate } from '../../redux/slices/translations';
import { useUserInfo } from '../../redux/modules/userinfo';
import { useOnScreen } from '../../utils/onScreenHelper';
import classes from './BatchUsersControlView.scss';
import { ValidatedBatchUser } from './BatchUsersControlView.validators';
import { useReducedMotion } from '../../utils/reduced-motion';

interface Props {
	user: ValidatedBatchUser;
	changeField: <T extends keyof BatchUser>(userId: string, field: T, value: BatchUser[T]) => void;
	deleteUser: (userId: string) => void;
	getUserNumberOptions: (user: BatchUser, domain: ApiDomain) => JSX.Element[];
	hasErrors: boolean;
	enforce?: string;
	isLastRemainingUser: boolean;
}

const BatchUserControlTableRow = ({
	user,
	changeField,
	deleteUser,
	hasErrors,
	getUserNumberOptions,
	enforce = '',
	isLastRemainingUser,
}: Props) => {
	const translate = useTranslate();
	const addresses = useAddresses();
	const userInfo = useUserInfo();
	const ref = React.useRef(null);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const isReducedMotionActive = useReducedMotion();

	const isInViewport = useOnScreen(ref, enforce);

	if (!addresses) {
		return null;
	}

	return (
		<tr
			id={`tableRow${user.id}`}
			key={user.id}
			ref={ref}
			onAnimationEnd={() => deleteUser(user.id)}
			className={classnames({
				[classes.hasErrors]: hasErrors,
				[classes.deleting]: isDeleting,
			})}
		>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_FIRSTNAME_HEADLINE')}>
				{isInViewport && (
					<Input
						placeholder={translate('PLACEHOLDER_FIRST_NAME')}
						onChange={event => changeField(user.id, 'firstname', event.currentTarget.value)}
						type="text"
						label=""
						error={
							user.validation.firstname && !user.validation.firstname.valid
								? user.validation.firstname.error
								: undefined
						}
						value={user.firstname ?? ''}
					/>
				)}
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_LASTNAME_HEADLINE')}>
				{isInViewport && (
					<Input
						placeholder={translate('PLACEHOLDER_LAST_NAME')}
						onChange={event => changeField(user.id, 'lastname', event.currentTarget.value)}
						error={
							user.validation.lastname && !user.validation.lastname.valid
								? user.validation.lastname.error
								: undefined
						}
						type="text"
						label=""
						value={user.lastname || ''}
					/>
				)}
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_EMAIL_HEADLINE')}>
				{isInViewport && (
					<Input
						placeholder={translate('PLACEHOLDER_EMAIL')}
						onChange={event => changeField(user.id, 'email', event.currentTarget.value)}
						error={
							user.validation.email && !user.validation.email.valid
								? user.validation.email.error
								: undefined
						}
						type="text"
						label=""
						value={user.email || ''}
					/>
				)}
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_ROLE_HEADLINE')}>
				<Checkbox onChange={value => changeField(user.id, 'admin', value)} checked={user.admin}>
					{translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_ROLE_IS_ADMIN_CHECKBOX_LABEL')}
				</Checkbox>
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_LOCATION_HEADLINE')}>
				{isInViewport && (
					<Select
						onChange={value => changeField(user.id, 'locationId', value)}
						error={
							user.validation.locationId && !user.validation.locationId.valid
								? user.validation.locationId.error
								: undefined
						}
						placeholder={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_LOCATION_DEFAULT')}
						title=""
						value={user.locationId ? user.locationId.toString() : ''}
					>
						{addresses.map(address => (
							<Option key={address.id} value={address.id.toString()}>
								{formatAddress(address)}
							</Option>
						))}
					</Select>
				)}
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_NUMBER_HEADLINE')}>
				{isInViewport && (
					<Select
						onChange={value => changeField(user.id, 'phoneNumberId', parseInt(value, 10))}
						placeholder={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_PHONENUMBER_DEFAULT')}
						title=""
						value={user.phoneNumberId ? `${user.phoneNumberId}` : ''}
						error={
							user.validation.phoneNumberId && !user.validation.phoneNumberId.valid
								? user.validation.phoneNumberId.error
								: undefined
						}
					>
						{getUserNumberOptions(user, userInfo.domain)}
					</Select>
				)}
			</td>
			<td data-label={translate('BATCH_USER_IMPORT_CONTROL_VIEW_TABLE_QUICKDIAL_HEADLINE')}>
				{isInViewport && (
					<Input
						placeholder={translate('PLACEHOLDER_EXTENSION_NUMBER')}
						onChange={event => changeField(user.id, 'quickdial', event.currentTarget.value)}
						error={
							user.validation.quickdial && !user.validation.quickdial.valid
								? user.validation.quickdial.error
								: undefined
						}
						type="text"
						label=""
						value={user.quickdial ?? ''}
					/>
				)}
			</td>
			<td>
				{isInViewport && (
					<Button
						onClick={() => {
							if (isReducedMotionActive || isLastRemainingUser) {
								deleteUser(user.id);
								return;
							}
							setIsDeleting(true);
						}}
						icon="delete"
						action="trigger"
						critical
						variant="quiet"
						iconOnly
						disabled={isDeleting}
					/>
				)}
			</td>
		</tr>
	);
};

export default BatchUserControlTableRow;
