import { Store } from '@reduxjs/toolkit';
import { State } from '../state';
import { UpdateNumbersAction } from './types';
import { PhoneNumber } from '../../../../redux/modules/numbers';

export const updateNumbers = (store: Store<State>) => (numbers: PhoneNumber[]) => {
	store.dispatch<UpdateNumbersAction>({
		type: 'UPDATE_NUMBERS',
		numbers,
	});
};
