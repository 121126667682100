import { instanaLog } from '../../third-party/instana';
import auth from '../../utils/authenticate/auth';
import { ApiError } from '../ApiError';

let reloading: boolean;

const DEFAULT_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
	'X-Sipgate-Client': 'app.sipgate.com',
	'X-Sipgate-Version': APP_WEB_VERSION, // eslint-disable-line no-undef
};

interface HttpClientOptions {
	baseUrl: string;
	abortSignal?: AbortSignal;
}

export class HttpClient {
	private apiUrl: string;

	private promiseCache = new Map<string, Promise<unknown>>();

	private abortSignal?: AbortSignal;

	public constructor(options: HttpClientOptions) {
		this.apiUrl = options.baseUrl;
		this.abortSignal = options.abortSignal;
	}

	private authHeader = async () => `Bearer ${(await auth.getToken()).access}`;

	private handleTokenInvalidation = async (response: Response, retry: () => Promise<Response>) => {
		if (response.status === 401) {
			auth.invalidateToken();

			return retry();
		}

		return response;
	};

	private handleReload = (response: Response) => {
		const shouldReload = response.headers.get('x-sipgate-reload');

		if (shouldReload && !reloading) {
			// Avoid reloading all clients at the exact same time to not break our backends
			const timeout = 1000 * Math.floor(Math.random() * 600);

			window.setTimeout(() => {
				window.location.reload();
			}, timeout);

			reloading = true;
		}

		return response;
	};

	private handleErrors = async (response: Response) => {
		if (!response.ok) {
			return response
				.json()
				.catch(e => {
					instanaLog.error(e);
					return null;
				})
				.then(json => {
					throw new ApiError(json, response.status, response.statusText);
				});
		}

		return response;
	};

	private extractContent = async (res: Response) => {
		const contentType = res.headers.get('content-type');

		if (!contentType) {
			return null;
		}

		if (contentType === 'application/json') {
			// This feels broken and can probably be replaced with res.json()
			const text = await res.text();

			try {
				return JSON.parse(text);
			} catch (e) {
				return text;
			}
		}

		return new Blob([await res.blob()], { type: contentType });
	};

	private applyMiddleware(query: () => Promise<Response>) {
		return () =>
			query()
				.then(res => this.handleTokenInvalidation(res, query))
				.then(this.handleReload)
				.then(this.handleErrors)
				.then(this.extractContent);
	}

	public cancellable = (abortSignal: AbortSignal) =>
		new HttpClient({
			baseUrl: this.apiUrl,
			abortSignal,
		});

	public get = (
		path: string,
		options: {
			mimeType?: string;
			unauthenticated?: boolean;
		} = {}
		// We explicitly use any instead of unknown, to make working with this easier.
		// The actual types are defined in RestApiClient.ts and noone should use the HttpClient
		// on its own.
		//
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> => {
		const url = this.apiUrl + path;
		const cachedPromise = this.promiseCache.get(url);

		if (cachedPromise && !this.abortSignal) {
			// This structured clone is here for the following wild interactions between this cache
			// and redux/immer.
			//
			// It can happen, that we fire two (force)-fetches at pretty much the same time. This cache
			// ensures only one actual request is done. We do however fire the reducer twice.
			//
			// The first time the reducer runs, we may take part of the payload and stuff it into our
			// state. If we then run again, we call our reducer with the (referentially)-same object, which
			// is already present in our redux store, so we may mutate part of our store by accident.
			//
			// Immer (correctly) catches this and is unhappy with us, so we use `structuredClone` to
			// ensure each invocation of our HttpClient returns a promise containing a new,
			// not-referenced object.
			return cachedPromise.then(structuredClone);
		}

		const query = this.applyMiddleware(async () =>
			fetch(url, {
				signal: this.abortSignal,
				method: 'get',
				redirect: 'follow',
				headers: {
					...DEFAULT_HEADERS,
					...(options.mimeType ? { Accept: options.mimeType } : {}),
					...(!options.unauthenticated ? { Authorization: await this.authHeader() } : {}),
				},
			})
		);

		const promise = query().finally(() => {
			if (!this.abortSignal) {
				this.promiseCache.delete(url);
			}
		});

		if (!this.abortSignal) {
			this.promiseCache.set(url, promise);
		}

		return promise;
	};

	public delete = (
		path: string,
		data: unknown = {}
		// We explicitly use any instead of unknown, to make working with this easier.
		// The actual types are defined in RestApiClient.ts and noone should use the HttpClient
		// on its own.
		//
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> => {
		const query = this.applyMiddleware(async () =>
			fetch(`${this.apiUrl}${path}`, {
				signal: this.abortSignal,
				method: 'delete',
				redirect: 'follow',
				headers: {
					...DEFAULT_HEADERS,
					Authorization: await this.authHeader(),
				},
				body: JSON.stringify(data),
			})
		);

		return query();
	};

	public post = (
		path: string,
		data: unknown = {}
		// We explicitly use any instead of unknown, to make working with this easier.
		// The actual types are defined in RestApiClient.ts and noone should use the HttpClient
		// on its own.
		//
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> => {
		const query = this.applyMiddleware(async () => {
			const res = await fetch(`${this.apiUrl}${path}`, {
				signal: this.abortSignal,
				method: 'post',
				redirect: 'follow',
				headers: {
					...DEFAULT_HEADERS,
					Authorization: await this.authHeader(),
				},
				body: JSON.stringify(data),
			});

			// We are building a sonderlocke for the contact create call (POST -> /v2/contacts)
			// because we want to extract the id of the newly created contact and not
			// break backwards compatibility on V2 API.
			if (res.status === 201) {
				const location = res.headers.get('location');
				if (location) {
					const locationGetResponse = await fetch(`${this.apiUrl}${location}`, {
						signal: this.abortSignal,
						method: 'get',
						redirect: 'follow',
						headers: {
							...DEFAULT_HEADERS,
							Authorization: await this.authHeader(),
						},
					});
					return locationGetResponse;
				}
			}

			return res;
		});

		return query();
	};

	public put = (
		path: string,
		data: unknown = {}
		// We explicitly use any instead of unknown, to make working with this easier.
		// The actual types are defined in RestApiClient.ts and noone should use the HttpClient
		// on its own.
		//
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> => {
		const query = this.applyMiddleware(async () =>
			fetch(`${this.apiUrl}${path}`, {
				signal: this.abortSignal,
				method: 'put',
				redirect: 'follow',
				headers: {
					...DEFAULT_HEADERS,
					Authorization: await this.authHeader(),
				},
				body: JSON.stringify(data),
			})
		);

		return query();
	};

	public patch = (
		path: string,
		data: unknown = {}
		// We explicitly use any instead of unknown, to make working with this easier.
		// The actual types are defined in RestApiClient.ts and noone should use the HttpClient
		// on its own.
		//
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> => {
		const query = this.applyMiddleware(async () =>
			fetch(`${this.apiUrl}${path}`, {
				signal: this.abortSignal,
				// Patch needs to be in CAPITAL because otherwise the preflight check will fail
				method: 'PATCH',
				redirect: 'follow',
				headers: {
					...DEFAULT_HEADERS,
					Authorization: await this.authHeader(),
				},
				body: JSON.stringify(data),
			})
		);

		return query();
	};
}
