import sha256 from 'sha256';

import { UserInfo } from '../redux/modules/userinfo';

export function appWebVersion() {
	return APP_WEB_VERSION; // eslint-disable-line no-undef
}

export function opaqueUserId(data: UserInfo) {
	return sha256(data.masterSipId + data.sub);
}

export function opaqueAccountId(data: UserInfo) {
	return sha256(data.masterSipId);
}

export function environment() {
	if (window.location.hostname === 'app.sipgate.com') {
		return 'live';
	}

	if (window.location.hostname === 'app.dev.sipgate.com') {
		return 'dev';
	}

	return 'local';
}

export function normalizePath(pathname: string) {
	const replaceToken = '#';

	return pathname
		.replace(new RegExp(`\\/new-click-to-dial\\/\\w+$`), `/new-click-to-dial/${replaceToken}`)
		.replace(new RegExp(`\\/add-contact\\/\\w+$`), `/add-contact/${replaceToken}`)
		.replace(/\/[0-9]+[a-z][0-9]+/gi, `/${replaceToken}`)
		.replace(/\/[a-z][0-9]+/gi, `/${replaceToken}`)
		.replace(/\/[0-9]+/gi, `/${replaceToken}`);
}
