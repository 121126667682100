import { createAction } from '../..';

import api from '../../../api';
import { mixpanel } from '../../../third-party/mixpanel';
import { ReduxState } from '../../types';
import { Trunk } from './types';

const shouldFetchDevices = (state: ReduxState, trunkId: string) =>
	!state.trunks.fetchedDevicesForTrunk.includes(trunkId) &&
	!state.trunks.fetchingDevicesForTrunk.includes(trunkId);

const shouldFetchContingents = (state: ReduxState, trunkId: string) =>
	!state.trunks.fetchedContingentsForTrunk.includes(trunkId) &&
	!state.trunks.fetchingContingentsForTrunk.includes(trunkId);

export const fetchTrunks = createAction('TRUNKS_FETCH', () => ({
	promise: () => api.getTrunks(),
	shouldFetch: (state: ReduxState) => !state.trunks.fetched && !state.trunks.fetching,
}));

export const forceFetchTrunks = createAction('TRUNKS_FETCH', () => ({
	promise: () => api.getTrunks(),
}));

export const fetchTrunkDevices = createAction('TRUNK_DEVICES_FETCH', (trunkId: string) => ({
	promise: () => api.getTrunkDevices(trunkId),
	shouldFetch: (state: ReduxState) => shouldFetchDevices(state, trunkId),
	data: { trunkId },
}));

export const forceFetchTrunkDevices = createAction('TRUNK_DEVICES_FETCH', (trunkId: string) => ({
	promise: api.getTrunkDevices(trunkId),
	data: { trunkId },
}));

export const fetchTrunkContingents = createAction('TRUNK_CONTINGENTS_FETCH', (trunkId: string) => ({
	promise: () => api.getTrunkContingentContracts(trunkId),
	shouldFetch: (state: ReduxState) => shouldFetchContingents(state, trunkId),
	data: { trunkId },
}));

export const forceFetchTrunkContingents = createAction(
	'TRUNK_CONTINGENTS_FETCH',
	(trunkId: string) => ({
		promise: api.getTrunkContingentContracts(trunkId),
		data: { trunkId },
	})
);

export const setTrunkCallerId = createAction(
	'TRUNKS_SET_CALLER_ID',
	(trunkId: string, callerId: number) => ({
		promise: () => api.setTrunkCallerId(trunkId, callerId),
		data: { trunkId, callerId },
	})
);

export const setTrunkEmergencyLocation = createAction(
	'TRUNKS_SET_EMERGENCY_LOCATION',
	(trunkId: string, addressId: string) => ({
		promise: () => api.setTrunkEmergencyLocation(trunkId, addressId),
		data: { trunkId, addressId },
	})
);

export const renameTrunk = createAction('TRUNKS_RENAME', (id: string, alias: string) => ({
	promise: () => api.renameTrunk(id, alias),
	data: { id, alias },
	restrictions: {
		restrictions: ['CAN_CREATE_TRUNK_DEVICE', 'CAN_DELETE_TRUNK_DEVICE', 'CAN_BOOK_TRUNKING'],
	},
}));

export const generateTrunkCredentialsPassword = createAction(
	'TRUNKS_GENERATE_CREDENTIALS_PASSWORD',
	(id: string) => ({
		promise: () => api.generateTrunkCredentialsPassword(id),
		data: { id },
		restrictions: {
			restrictions: ['CAN_CREATE_TRUNK_DEVICE', 'CAN_DELETE_TRUNK_DEVICE', 'CAN_BOOK_TRUNKING'],
		},
	})
);

export const deleteTrunk = createAction('TRUNKS_DELETE', (id: string) => ({
	promise: () => api.deleteTrunk(id),
	data: { id },
	restrictions: {
		restrictions: ['CAN_CREATE_TRUNK_DEVICE', 'CAN_DELETE_TRUNK_DEVICE', 'CAN_BOOK_TRUNKING'],
	},
}));

export const changeTrunkRouting = createAction(
	'TRUNKS_CHANGE_ROUTING',
	(
		trunk: Trunk,
		change: {
			added: string[];
			removed: string[];
		}
	) => ({
		promise: (async () => {
			if (change.added.length > 0) {
				await api.addTrunkRouting(trunk.id, change.added);
			}

			if (change.removed.length > 0) {
				await api.removeTrunkRouting(trunk.id, change.removed);
			}
		})(),
		data: {
			trunk,
			change,
		},
	})
);

export const cancelTrunkContingentContract = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_CANCEL',
	(trunkId: string, contractId: string) => ({
		promise: () => api.cancelTrunkContingentContract(trunkId, contractId, null),
		data: { trunkId, contractId },
	})
);

export const revokeTrunkContingentContractCancellation = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_REVOKE_CANCELLATION',
	(trunkId: string, contractId: string) => ({
		promise: () => api.revokeDeviceOrTrunkContingentContractCancellation(trunkId, contractId),
		data: { trunkId, contractId },
	})
);

export const createTrunkContingentContract = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_CREATE',

	(
		/** trunk ID including mastersipid for some reason... */
		trunkId: string,
		productId: string,
		productName: string
	) => ({
		promise: api.createTrunkContingentContract(trunkId, productId).then(result => {
			mixpanel.track('Booked contract', {
				'Contract id': productId,
				'Contract name': productName,
				'Contract type': 'Trunking contingent',
				'Contract booking type': 'Initial Booking',
			});

			return result;
		}),
		data: { trunkSipId: trunkId, productId },
	})
);
