import React from 'react';
import classnames from 'classnames';

import { localizeNumber } from '@web-apps/phonenumbers-utils';
import classes from './PhonenumberSuggestion.scss';
import { ContactSearchResult } from '../../../redux/modules/contacts';
import { SipgateDomain } from '../../../redux/modules/userinfo';

export interface Suggestion extends ContactSearchResult {
	id: number;
	numberMatchLocation: null | {
		start: number;
		length: number;
	};
	contactMatchLocation: null | {
		start: number;
		length: number;
	};
}

interface Props {
	selected: boolean;
	className?: string;
	suggestion: Suggestion;
	domain: SipgateDomain;

	onSelect: (id: number) => void;
	onSubmit: (id: number) => void;
}

export class PhonenumberSuggestion extends React.PureComponent<Props> {
	private onSelect = () => {
		this.props.onSelect(this.props.suggestion.id);
	};

	private onSubmit = () => {
		this.props.onSubmit(this.props.suggestion.id);
	};

	private renderHighlightedText(text: string, highlight: { start: number; length: number }) {
		return (
			<>
				{text.substr(0, highlight.start)}
				<span className={classes.highlighted}>
					{text.substr(highlight.start, highlight.length)}
				</span>
				{text.substr(highlight.start + highlight.length)}
			</>
		);
	}

	public render() {
		const localizedNumber = localizeNumber(this.props.suggestion.number, this.props.domain);

		const highlightedNumber = this.props.suggestion.numberMatchLocation
			? this.renderHighlightedText(localizedNumber, this.props.suggestion.numberMatchLocation)
			: localizedNumber;

		const highlightedContact = this.props.suggestion.contactMatchLocation
			? this.renderHighlightedText(
					this.props.suggestion.contact.name,
					this.props.suggestion.contactMatchLocation
				)
			: this.props.suggestion.contact.name;

		return (
			<li
				key={`${this.props.suggestion.contact.id}-${this.props.suggestion.number}`}
				className={classnames({ [classes.selected]: this.props.selected }, this.props.className)}
			>
				<button
					className={classes.phonenumberSuggestion}
					onClick={this.onSubmit}
					onMouseOver={this.onSelect}
					onFocus={this.onSelect}
					type="button"
				>
					<span className={classes.number}>{highlightedNumber}</span>&nbsp;
					<span className={classes.name}>{highlightedContact}</span>
				</button>
			</li>
		);
	}
}
