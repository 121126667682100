import {
	GermanLandlineProlongationChild,
	hasRoutingPhoneline,
	isPhonenumberBlock,
	isPhonenumberInOngoingImportPorting,
	isPhonenumberWithChildren,
	Phonenumber,
} from './types';

export const findPhonenumber = (
	phonenumbers: (Phonenumber | GermanLandlineProlongationChild)[],
	e164Number: string
) => {
	const searchQueue = [...phonenumbers];

	while (searchQueue.length > 0) {
		const phonenumber = searchQueue.shift();

		if (phonenumber && phonenumber.e164Number === e164Number) {
			return phonenumber;
		}

		if (phonenumber && isPhonenumberWithChildren(phonenumber)) {
			searchQueue.push(...phonenumber.numbers);
		}
	}

	return undefined;
};

export const flatMapRoutablePhonenumbers = <T>(
	phonenumbers: (Phonenumber | GermanLandlineProlongationChild)[],
	mapFunction: (number: Phonenumber | GermanLandlineProlongationChild) => T
) => {
	return phonenumbers.flatMap(n => {
		if (isPhonenumberWithChildren(n)) {
			const children = n.numbers.map(mapFunction);
			if (n.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT') {
				children.push(mapFunction(n));
			}
			return children;
		}
		return mapFunction(n);
	});
};

export const selectPhonenumbersForWebusers = (
	phonenumbers: (Phonenumber | GermanLandlineProlongationChild)[],
	webuserIds: string[]
): Phonenumber[] =>
	phonenumbers.flatMap(phonenumber => {
		if (isPhonenumberWithChildren(phonenumber)) {
			const parentHasUserRouting =
				hasRoutingPhoneline(phonenumber) && webuserIds.includes(phonenumber.routing.targetOwner);

			return parentHasUserRouting
				? [phonenumber, ...selectPhonenumbersForWebusers(phonenumber.numbers, webuserIds)]
				: [...selectPhonenumbersForWebusers(phonenumber.numbers, webuserIds)];
		}

		if (hasRoutingPhoneline(phonenumber) && webuserIds.includes(phonenumber.routing.targetOwner)) {
			return [phonenumber];
		}

		return [];
	});

export const isCancelablePhonenumber = (
	number: Phonenumber | GermanLandlineProlongationChild,
	type?: string
) => {
	if (type === 'single' && number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT') {
		return (
			number.baseContract &&
			number.baseContract.nextCancellationDate &&
			!number.baseContract.cancellation
		);
	}

	return number.contract && number.contract.nextCancellationDate && !number.contract.cancellation;
};

export const isRevokablePhonenumber = (
	number: Phonenumber | GermanLandlineProlongationChild,
	type?: 'single' | 'multiple'
) => {
	if (
		/*
		 * Wants to revoke prolongation so we need to look for the basecontract
		 * and when there is a cancellation we can't revoke the children contract.
		 */
		(type === 'multiple' &&
			number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			number.baseContract.cancellation) ||
		/*
		 * Wants to revoke preprolongation so we need to look for the basecontract
		 * and when there is no cancellation we can't revoke it.
		 */
		(type === 'single' &&
			number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			!number.baseContract.cancellation)
	) {
		return false;
	}

	return number.contract && number.contract.cancellation && number.contract.cancellation.revocable;
};

export const findPhonenumbersNotInOngoingImportPorting = (
	numbers: (Phonenumber | GermanLandlineProlongationChild)[]
) => {
	return numbers.filter(phonenumber => {
		if (isPhonenumberBlock(phonenumber)) {
			return phonenumber.numbers.every(number => !isPhonenumberInOngoingImportPorting(number));
		}
		return !isPhonenumberInOngoingImportPorting(phonenumber);
	});
};
