import { combineReducers } from '@reduxjs/toolkit';
import faxlines from './modules/faxlines';
import { reducer as loadingIndicator } from './slices/loadingIndicator';
import notifications from './modules/notifications';
import portings from './modules/portings';
import { reducer as smsCallerIds } from './slices/smsCallerIds';
import { reducer as account } from './slices/account';
import { reducer as accounting } from './slices/accounting';
import { reducer as affiliate } from './slices/affiliate';
import { reducer as addresses } from './modules/addresses';
import { reducer as appProperties } from './slices/appProperties';
import { reducer as blocklist } from './slices/blocklist';
import { reducer as callRecorder } from './slices/callRecorder';
import { reducer as callRestrictions } from './modules/callRestrictions/webuser';
import { reducer as changelog } from './slices/changelog';
import { reducer as contacts } from './modules/contacts';
import { reducer as contracts } from './modules/contracts';
import { reducer as deviceLocalprefix } from './modules/deviceLocalprefix';
import { reducer as deviceProperties } from './modules/deviceProperties';
import { reducer as devices } from './modules/devices';
import { reducer as dialogs } from './slices/snackbar';
import { reducer as events } from './modules/events';
import { reducer as forwardings } from './modules/timeBasedForwardings';
import { reducer as googleContactsReducer } from './modules/googleContacts';
import { reducer as groupStatistics } from './slices/groupStatistics';
import { reducer as groups } from './modules/groups';
import { reducer as holidayPresets } from './slices/holidayPresets';
import { reducer as identityVerification } from './modules/identityVerification';
import { reducer as linksReducer } from './slices/links';
import { reducer as lpaUris } from './slices/esim';
import { reducer as mobileDeviceTariffAnnouncements } from './modules/mobileDeviceTariffAnnouncements';
import { reducer as numbers } from './modules/numbers';
import { reducer as personalAccessTokens } from './modules/personalAccessToken';
import { reducer as phoneProvisioning } from './modules/phoneProvisioning';
import { reducer as phonelineBlockAnonymous } from './modules/phonelineBlockAnonymous';
import { reducer as phonelines } from './modules/phonelines';
import { reducer as products } from './modules/products';
import { reducer as productsTacs } from './modules/productsTacs';
import { reducer as pseudo } from './modules/pseudo';
import { reducer as restrictions } from './modules/restrictions';
import { reducer as scopes } from './modules/scopes';
import { reducer as screenreaderAnnouncements } from './slices/screenreaderAnnouncements';
import { reducer as sipgateStatus } from './slices/sipgatestatus';
import { reducer as smsReducer } from './slices/sms';
import { reducer as ssoConfiguration } from './modules/ssoConfiguration';
import { reducer as tacs } from './slices/tacs';
import { reducer as transferAudioFiles } from './modules/transferAudioFiles';
import { reducer as translations } from './slices/translations';
import { reducer as trunkCallRestrictions } from './modules/callRestrictions/trunk';
import { reducer as trunkContracts } from './modules/trunkContracts';
import { reducer as trunks } from './modules/trunks';
import { reducer as twoFactorAuthenticationSettings } from './modules/twoFactorAuthenticationSettings';
import { reducer as userinfo } from './modules/userinfo';
import { reducer as users } from './modules/users';
import { reducer as voicemails } from './modules/voicemails';
import { reducer as webphone } from './modules/webphone';
import { reducer as dataAutomations } from './modules/dataAutomation';
import { reducer as dataAutomationHistory } from './modules/dataAutomationHistory';
import { reducer as dataUsage } from './modules/dataUsage';
import { reducer as microsoftTeams } from './modules/microsoftTeams';
import { reducer as microsoftTeamsRoutingConfiguration } from './modules/microsoftTeamsRoutingConfiguration';
import { reducer as conferenceRoom } from './slices/conferenceRoom';
import { reducer as ivrs } from './modules/ivrs';
import { reducer as mobile } from './modules/mobile';
import { reducer as audioFiles } from './modules/audioFiles';
import { appSubscriptionReducer as appSubscriptions } from './modules/app';
import { reducer as dateBasedForwardings } from './slices/dateBasedForwardings';
import { reducer as acds } from './slices/acds';
import { reducer as acdAudioFiles } from './slices/acdAudioFiles';
import { reducer as acdStatistics } from './slices/acdStatistics';
import { reducer as domainVerification } from './slices/domainVerification';
import { reducer as igel } from './slices/igel';
import { reducer as callRecordingAudioFiles } from './modules/callRecordingAudioFiles';
import { reducer as reports } from './modules/reports';
import { reducer as userNotifications } from './modules/userNotifications';
import { reducer as labels } from './modules/labels';
import { reducer as sipgateIo } from './slices/sipgate-io';
import { reducer as batchUserImport } from './slices/batchUserImport';
import { reducer as phonenumbers } from './slices/phonenumbers';
import { reducer as apiClients } from './slices/apiClients';
import { reducer as payment } from './slices/payment';
import { reducer as appConnectedIntegrations } from './slices/connectedAppIntegrations';
import { reducer as avatars } from './slices/avatar';
import { reducer as balance } from './slices/balance';
import { reducer as sipgateApp } from './slices/sipgateApp';
import { reducer as sipgateAppNotification } from './slices/sipgateAppNotification';
import { reducer as joyrides } from './slices/joyrides';
import { reducer as nqNupsi } from './slices/nqNupsi';
import { reducer as baseContract } from './slices/baseContract';

export const rootReducer = combineReducers({
	account,
	acdAudioFiles,
	acds,
	acdStatistics,
	accounting,
	affiliate,
	addresses,
	appConnectedIntegrations,
	appProperties,
	appSubscriptions,
	audioFiles,
	avatars,
	balance,
	baseContract,
	batchUserImport,
	blocklist,
	callRecorder,
	callRecordingAudioFiles,
	callRestrictions,
	changelog,
	conferenceRoom,
	contacts,
	contracts,
	dataAutomationHistory,
	dataAutomations,
	dataUsage,
	dateBasedForwardings,
	deviceLocalprefix,
	deviceProperties,
	devices,
	dialogs,
	domainVerification,
	events,
	faxlines,
	forwardings,
	googleContacts: googleContactsReducer,
	groupStatistics,
	groups,
	holidayPresets,
	identityVerification,
	igel,
	ivrs,
	links: linksReducer,
	loadingIndicator,
	lpaUris,
	microsoftTeams,
	microsoftTeamsRoutingConfiguration,
	mobile,
	mobileDeviceTariffAnnouncements,
	notifications,
	numbers,
	nqNupsi,
	personalAccessTokens,
	phoneProvisioning,
	phonelineBlockAnonymous,
	phonelines,
	portings,
	products,
	productsTacs,
	pseudo,
	restrictions,
	scopes,
	screenreaderAnnouncements,
	sipgateStatus,
	sipgateApp,
	sipgateAppNotification,
	joyrides,
	sms: smsReducer,
	smsCallerIds,
	ssoConfiguration,
	tacs,
	transferAudioFiles,
	translations,
	trunkCallRestrictions,
	trunkContracts,
	trunks,
	twoFactorAuthenticationSettings,
	userinfo,
	users,
	voicemails,
	webphone,
	reports,
	userNotifications,
	labels,
	sipgateIo,
	phonenumbers,
	payment,
	apiClients,
});
