import { useMemo } from 'react';
import { useSelector } from '../../utils/wrapper';
import { buildStringOnlyTranslate, buildTranslate } from './helper';
import { useAction } from '../../index';
import { fetchTranslations, setDebug } from './actions';

export const useTranslate = () => {
	const translations = useSelector(state => state.translations.items);
	const debug = useSelector(state => state.translations.debug);

	// TODO: remove when we find a better solution for screenshot tests
	const fake = useSelector(state => state.translations.fake);

	return useMemo(() => {
		if (fake) {
			return buildTranslate(
				(lk: string, ...args: unknown[]) =>
					`${lk
						.split('_')
						.map(part => part.slice(0, 2))
						.join('_')
						.substr(0, 10)}${args
						.map(a => (typeof a === 'object' ? ` ${JSON.stringify(a)}` : ` ${a}`))
						.join('')}`
			);
		}

		return buildTranslate(buildStringOnlyTranslate(translations, debug));
	}, [translations, debug, fake]);
};

export const useTranslationActions = () => ({
	fetchTranslations: useAction(fetchTranslations),
	setDebug: useAction(setDebug),
});
