import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchReferredCustomers = (state: ReduxState) =>
	!state.affiliate.fetching && !state.affiliate.fetched;

export const fetchReferredCustomers = createAsyncThunk(
	'affiliate/fetch',
	async (_forceFetch?: boolean) => api.getReferredCustomers(),
	{
		condition: (forceFetch, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchReferredCustomers(getState());
		},
	}
);

const shouldFetchCommissionPayoutIban = (state: ReduxState) =>
	!state.affiliate.fetchingCommissionPayoutAccount &&
	!state.affiliate.fetchedCommissionPayoutAccount;

export const fetchCommissionPayoutAccount = createAsyncThunk(
	'affiliate/fetchCommissionPayoutAccount',
	async (_forceFetch?: boolean) => api.fetchCommissionPayoutAccount(),
	{
		condition: (forceFetch, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchCommissionPayoutIban(getState());
		},
	}
);

export const createCommissionPayoutAccount = createAsyncThunk(
	'affiliate/createCommissionPayoutAccount',
	({ iban, accountHolder }: { iban: string; accountHolder: string }) =>
		api.createCommissionPayoutAccount(iban, accountHolder)
);

export const deleteCommissionPayoutAccount = createAsyncThunk(
	'affiliate/deleteCommissionPayoutIban',
	() => api.deleteCommissionPayoutAccount()
);

export const fetchReferallComissions = createAsyncThunk(
	'affiliate/fetchCommissions',
	async ({ startDate, endDate }: { startDate: string; endDate?: string }) =>
		api.getReferallCommissions(startDate, endDate)
);

export const fetchCommissionBalance = createAsyncThunk('affiliate/fetchBalance', async () =>
	api.getCommissionBalance()
);

export const fetchCommissionReceipts = createAsyncThunk(
	'affiliate/fetchCommissionReceipts',
	async ({ startDate, endDate }: { startDate: string; endDate?: string }) =>
		api.getCommissionReceipts(startDate, endDate)
);

export const consentAffiliateLogin = createAsyncThunk('affiliate/consentAffiliateLogin', async () =>
	api.consentAffiliateLogin()
);

export const revokeAffiliateLoginConsent = createAsyncThunk(
	'affiliate/revokeAffiliateLoginConsent',
	async () => api.revokeAffiliateLogin()
);

export const fetchAffiliateLoginConsentStatus = createAsyncThunk(
	'affiliate/fetchAffiliateLoginConsentStatus',
	async () => api.getAffiliateLoginConsentStatus()
);

export const payoutCommissionBalance = createAsyncThunk(
	'affiliate/payoutBalance',
	async ({ iban, accountHolder }: { iban: string; accountHolder: string }) =>
		api.payoutCommissionBalance(iban, accountHolder)
);

export const fetchAffiliateInfo = createAsyncThunk('affiliate/fetchAffiliateInfo', async () =>
	api.fetchAffiliateInfo()
);
