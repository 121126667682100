import { DateTime } from 'luxon';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchAccountStatement = (state: ReduxState) =>
	!state.accounting.fetchingAccountStatement && !state.accounting.fetchedAccountStatement;

export const fetchAccountStatement = createAsyncThunk(
	'accounting/fetchAccountStatement',
	async ({
		periodStart,
		periodEnd,
		creditingTypeFilters,
	}: {
		periodStart: DateTime;
		periodEnd: DateTime;
		creditingTypeFilters: string[];
		forceFetch?: boolean;
	}) => api.getAccountStatement(periodStart, periodEnd, creditingTypeFilters),
	{
		condition: ({ forceFetch }, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchAccountStatement(getState());
		},
	}
);
